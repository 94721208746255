import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  getSurveySections,
  getSurveySectionsItems,
  delChecklistItemAttachment,
  getCheckListItemStatus,
  getChecklistItemAttachmentv3,
  getCheckListTable,
} from "../../../../api/index";
import { MessageCircle } from "react-feather";
// import { getData, postData, putData } from "../../../../api/APIinstance";
import {
  axiosPostData,
  axiosPutData,
  deleteApi,
  getData,
  putData,
  postData,
} from "../../../../api/APIinstance";
import ChatUI from "../surveyList/surveyDashboard/surveySectionList/ChatUI";
import GuestUserLinkGeneration from "../../../ProjectComponents/CustomerAdmin/SuveyManagement/GuestUserLinkGeneration";
import db from "../../../../data/config";
import { validateCallMinsWithSubscriptionPlan } from "../../../ProjectComponents/Common/Functions/CommonFunctions";
import { getAuth } from "firebase/auth";
import VideoApp from "../../videoCall/VideoApp";
import Slider from "react-slick";
import fileimg from "../../../../assets/images/Vedam/fileimg.png";
import { XCircle, Eye, Camera, Video } from "react-feather";
import SweetAlert from "sweetalert2";
import { generateUserId } from "../../../ProjectComponents/Common/Functions/CommonFunctions";
import html2canvas from "html2canvas";
import AddPhotosVideos from "../../../../components/dashboard/defaultCompo/surveyList/surveyDashboard/surveySectionList/addPhotosVideos";
import {
  uploadChecklistItemAttachment,
  renameChecklistItemAttachment,
  getChecklistItemAttachment,
  getNonConfirmsList, get_nonconformance_sections, getNCSurveySectionsItems
} from "../../../../api/index";
import OnlySurveyDocs from "./OnlySurveyDocs";
// import FormsOfSurvey from "./FormsOfSurvey";
import FormsOfSurvey2 from "./FormsOfSurvey2";
import Sire_flow from "./Sire_flow";
import SurveyForm from "../surveyList/surveyDashboard/SurveyForm";

function ConductSurvey() {
  const navigate = useNavigate();
  const location = useLocation();
  const myRef = React.useRef(null);

  const [isOpen, setIsOpen] = useState(0);
  const [risOpen, rsetIsOpen] = useState(0);
  const [isOpen2, setIsOpen2] = useState(0);
  const [linkModal, setLinkModal] = useState(false);

  const [answer, setAnswer] = useState("");
  const [comments, setComments] = useState("");
  const [surveyDone, setSurveyDone] = useState(false);

  const [modal8, setModal8] = useState();
  const [ncList, setNcList] = useState({ data: [], loading: false });

  const [attachmentModal, setAttachmentModal] = useState();
  const [imgPreview, setImgPreview] = useState({
    type: null,
    file: null,
    data: null,
  });

  const localData = JSON.parse(localStorage.getItem("login_data"));
  const { id, cid } = useParams();

  const [sectionList, setSectionList] = useState({ data: [], loading: false });
  const [checklistItemList, setChecklistItemList] = useState({
    data: [],
    loading: false,
  });

  const [sire_outcome, setSire_outcome] = useState([]);

  const [call, setCall] = useState(false);
  const [photosVideos, setPhotosVideos] = useState({
    data: [],
    loading: false,
  });
  const [recordingVideos, setRecordingVideos] = useState([]);
  const [preview, setPreview] = useState([]);
  const [linkRecordingModal, setLinkRecordingModal] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const [surveyItemStatus, setSurveyItemStatus] = useState({
    data: [],
    loading: false,
  });
  const [chatContainer, setChatContainer] = useState(false);
  const [open1, setOpen] = useState();
  const [unReadMsgCount, setUnreadMsgCount] = useState(0);

  const [linkRecordingVideos, setLinkRecordingVideos] = useState([]);

  const [selectedRecordedVideo, setSelectedRecordedVideo] = useState();

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const [filename, setFilename] = useState("");
  const [filenameErr, setFilenameErr] = useState("");
  const [filename2, setFilename2] = useState("");
  const [filenameErr2, setFilenameErr2] = useState("");
  const [NCFilter, setNCFilter] = useState(false);
  const [showdoc, setShowdoc]=useState(false);
  const [showtable,setShowtable]=useState(false);
  const firebaseAuthFail = JSON.parse(localStorage.getItem("firebaseAuthFail"));
  const subscriptionPlan = JSON.parse(localStorage.getItem("subscription"));

  const ref = db.collection("notifications");
  const guestUserdata = JSON.parse(localStorage.getItem("guestUserData"));
  const GuestVideoCall = JSON.parse(localStorage.getItem("GuestVideoCall"));
  const emailAddress = JSON.parse(localStorage.getItem("login_data"));
  
  const [basic_settings, setBasicSetting] = useState({
    dots: true,
    infinite: true,
    speed: 100,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const toggleLinkModal = () => {
    setLinkModal(!linkModal);
  };

  const executeScroll = () => {
    console.log("m there");
    myRef.current.scrollIntoView();
    myRef.current.focus();
  };

  useEffect(async () => {
    localStorage.setItem("GuestVideoCall", false);
    console.log("location", location);
    console.log("location.state.data.surveyType", location.state.data.surveyType);
    window.scrollTo(0, 0);

    navigator.geolocation.getCurrentPosition((position) => {
      console.log("position.coords.latitude", position.coords.latitude);
      console.log("position.coords.longitude", position.coords.longitude);
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });

    console.log("localData", localData);
    // const reqPayload = {
    //     customer_id: localData.customer_id,
    //     survey_id: id,
    //     user: localData.user
    // }

    if (firebaseAuthFail === false) {
      await calUnreadMsgCount();
    }
    fetchSurveyStatus();

    const reqPayload2 = {
      customer_id: localData.customer_id,
      Id: id,
      user: localData.user,
    };
    await fetchSurveySections(reqPayload2);
    await fetchNonConfirms();
  }, []);

  function fetchNonConfirms() {
    setNcList({ ...ncList, loading: true, data: [] });
    getNonConfirmsList({ customerId: localData.customer_id }).then((res) => {
      console.log("res", res)
      if (res.data.payload.length) {
        setNcList({ ...ncList, loading: false, data: res.data.payload });
      }
    }, (err) => {
      console.log("err", err)
    })
  }

  async function fetchSurveyStatus() {
    setSurveyItemStatus({ ...surveyItemStatus, loading: true, data: [] });
    const reqPayload = {
      customer_id: localData.customer_id,
      survey_id: id,
      user: localData.user,
    };
    await getCheckListItemStatus(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log(
          "res.data.SurveyData[0].checklist",
          res.data.SurveyData[0].checklist
        );
        setSurveyItemStatus({
          ...surveyItemStatus,
          loading: false,
          data: res.data.SurveyData,
        });

        if (res.data.survey_test_pass) {
          setSurveyDone(true);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  useEffect(() => {
    if (call === false) {
      setBasicSetting({
        ...basic_settings,
        slidesToShow: 5,
        slidesToScroll: 5,
      });
    }
  }, [call]);

  const toggle8 = () => {
    setModal8(!modal8);
  };

  const recordingToggle = (event) => {
    setLinkRecordingModal(!linkRecordingModal);
  };

  const AttachmentToggle = (event) => {
    setAttachmentModal(!attachmentModal);
  };

  const toggle = async (i, sec_id) => {
    console.log("id", i);

    if (isOpen === i) {
      setIsOpen(null);
    } else {
      setIsOpen(i);

      console.log("sec_id", sec_id);
      const reqPayload = {
        customer_id: localData.customer_id,
        survey_id: id,
        user: localData.user,
        section_id: sec_id,
      };
      console.log("NCFilter", NCFilter)
      if (NCFilter == true) {
        fetchNCItems(sec_id)
      } else {
        await fetchSurveySectionItems(reqPayload);

      }
    }
  };


  function fetchNCItems(secId) {
    const reqPayload = {
      customer_id: localData.customer_id,
      survey_id: id,
      user: localData.user,
      section_id: secId,
    };
    setChecklistItemList({ ...checklistItemList, data: [], loading: true });
    delete reqPayload.user
    getNCSurveySectionsItems(reqPayload).then((res) => {
      console.log("res", res);
      if (res.data.checklist_sections.checklist.length > 0) {
        checklistItemList.data = res.data.checklist_sections.checklist;
        checklistItemList.loading = false;


        setChecklistItemList({
          ...checklistItemList,
          data: checklistItemList.data,
          loading: checklistItemList.loading,
        });

        if (res.data.checklist_sections.checklist[0].checklist_item_result.length > 0) {

          setAnswer(
            res.data.checklist_sections.checklist[0].checklist_item_result[
              res.data.checklist_sections.checklist[0].checklist_item_result.length - 1
            ].values
          );
          setComments(
            res.data.checklist_sections.checklist[0].checklist_item_result[
              res.data.checklist_sections.checklist[0].checklist_item_result.length - 1
            ].remarks
          );
        } else {
          setAnswer("");
          setComments("");
        }

      } else {
        setChecklistItemList({
          ...checklistItemList,
          data: [],
          loading: false,
        });
      }
    }, (err) => {
      console.log("err", err);
    })
  }

  const toggle2 = (id, sec_id) => {
    console.log("id", id);
    console.log("isOpen2", isOpen2);

    if (isOpen2 === id) {
      setIsOpen2(null);
    } else {
      setIsOpen2(id);
      console.log("checklistItemList.data", checklistItemList.data);

      if (checklistItemList.data[id - 1].checklist_item_result.length > 0) {
        console.log(
          checklistItemList.data[id - 1].checklist_item_result[
            checklistItemList.data[id - 1].checklist_item_result.length - 1
          ].values
        );
        setAnswer(
          checklistItemList.data[id - 1].checklist_item_result[
            checklistItemList.data[id - 1].checklist_item_result.length - 1
          ].values
        );
        setComments(
          checklistItemList.data[id - 1].checklist_item_result[
            checklistItemList.data[id - 1].checklist_item_result.length - 1
          ].remarks
        );
      } else {
        setAnswer("");
        setComments("");
      }
    }
  };
  const rtoggle = (id, sec_id) => {
    console.log("id", id);

    if (risOpen === id) {
      rsetIsOpen(null);
    } else {
      rsetIsOpen(id);
    }
  };

  async function fetchSurveySections(reqPayload) {
    setSectionList({ ...sectionList, data: [], loading: true });
    await getSurveySections(reqPayload).then(
      (res) => {
        console.log("res", res);

        if (res.data.res.length > 0) {
          console.log(
            "res.data.res[0].SOP.methodology",
            JSON.parse(res.data.res[0].SOP.methodology.values)
          );
          setSectionList({
            ...sectionList,
            data: res.data.res[0],
            loading: false,
          });
        } else {
          setSectionList({ ...sectionList, data: [], loading: false });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  async function fetchSurveySectionItems(reqPayload) {
    if (location.state.data.surveyType == "Sire") {
      reqPayload.sire = true;
    }
    setChecklistItemList({ ...checklistItemList, data: [], loading: true });
    await getSurveySectionsItems(reqPayload).then(
      (res) => {
        console.log("res", res);

        if (res.data.checklist_sections.checklist.length > 0) {
          checklistItemList.data = res.data.checklist_sections.checklist;
          checklistItemList.loading = false;


          setSire_outcome(res.data.checklist_sections.checklist)

          setChecklistItemList({
            ...checklistItemList,
            data: checklistItemList.data,
            loading: checklistItemList.loading,
          });
        } else {
          setChecklistItemList({
            ...checklistItemList,
            data: [].checklist,
            loading: false,
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  // function for check if the customer plan have subscription mins left, if not, not allowing to make call
  async function checkTotalCustSecs() {
    console.log("firebaseAuthFail", firebaseAuthFail);
    // If subscription is not present no restriction or firebase validation
    if (firebaseAuthFail) {
      toast.error(
        "Unable to calculate video call minutes, please contact admin!",
        {
          autoClose: 3000,
          theme: "light",

        }
      );
    }

    if (Object.keys(subscriptionPlan).length > 0) {
      if (firebaseAuthFail === false) {
        validateCallMinsWithSubscriptionPlan(
          localData.customer_id,
          subscriptionPlan.startDate,
          subscriptionPlan.endDate,
          subscriptionPlan.plan.callMinutes
        ).then((res) => {
          console.log("res", res);
          if (res === false) {
            toast.error(
              "Your subscription limit has been exhausted, contact admin !",
              {
                autoClose: 3000,
                theme: "light",

              }
            );
          } else {
            setOpen(true);
            setCall(true);
            setBasicSetting({
              ...basic_settings,
              slidesToShow: 2,
              slidesToScroll: 2,
            });

            document.querySelector(".page-main-header").classList.add("open");
            document.querySelector(".page-sidebar").classList.add("open");
          }
        });
      } else {
        setOpen(true);
        setCall(true);
        setBasicSetting({
          ...basic_settings,
          slidesToShow: 2,
          slidesToScroll: 2,
        });

        document.querySelector(".page-main-header").classList.add("open");
        document.querySelector(".page-sidebar").classList.add("open");
      }
    } else {
      console.log("ssssssssssssshould runnnnnnnnnnnnnnnn");
      setOpen(true);
      setCall(true);
      setBasicSetting({
        ...basic_settings,
        slidesToShow: 2,
        slidesToScroll: 2,
      });

      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    }
  }

  const deletePhoto = async (data, secId) => {
    console.log("data", data);
    console.log("secId", secId);

    const params = {
      Id: data.Id,
    };
    delChecklistItemAttachment(params).then(
      (res) => {
        console.log("res", res);
        toast.success("Attachment Deleted Successfully...", {
          autoClose: 3000,
          theme: "light",

        });
        const reqPayload = {
          customer_id: localData.customer_id,
          survey_id: id,
          user: localData.user,
          section_id: secId,
        };
        fetchSurveySectionItems(reqPayload);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  function checkLocationPermission(secId, checklistItem) {
    navigator.permissions
      .query({
        name: "geolocation",
      })
      .then(function (result) {
        if (result.state == "granted") {
          console.log("result.state", result.state);
          captureSS(secId, checklistItem);
        } else if (result.state == "prompt") {
          console.log("result.state", result.state);
          if (result.state == "granted") {
            captureSS(secId, checklistItem);
          }
          if (result.state == "denied") {
            SweetAlert.fire({
              title:
                "Unable to capture location, please enable browser location, refresh & try again !",
              icon: "error",
            });
          }
        } else if (result.state == "denied") {
          SweetAlert.fire({
            title:
              "Unable to capture location, please enable browser location, refresh & try again !",
            icon: "error",
          });
          console.log("result.state", result.state);
        }
      });
  }

  async function takeSSReturn() {
    const fileName = generateUserId();

    let iconToHide = document.getElementById("ssIconOnVideoCall");
    iconToHide?.classList?.add("d-none");

    let div = document.getElementById("screenshot");
    let canvas = await html2canvas(div);
    let a = document.createElement("a");
    a.download = "ss.png";
    a.href = canvas.toDataURL("image/png");

    let response = await fetch(a.href, {
      headers: { "Content-Type": "image/png" },
    });
    let fileObject = await response.blob();
    let file = new File([fileObject], `${fileName}`, {
      type: "image/png",
    });
    console.log(
      "file object==============================================================",
      file
    );
    return file;
  }

  const captureSS = async (secId, checklistItem) => {
    const fileName = generateUserId();
    const fileName2 = generateUserId();

    let iconToHide = document.getElementById("ssIconOnVideoCall");
    const ssFile = await takeSSReturn();

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(async (loc) => {
    //     console.log("loc", loc);
    //     console.log("ssFile", ssFile);
    console.log("lat", lat);
    console.log("lng", lng);
    if (lat && lng) {
      const formData = new FormData();
      const formDataNoChecklistId = new FormData();
      console.log(checklistItem.Id);
      formData.append("checkListItem", checklistItem.Id);

      formData.append("checklist_file", ssFile);
      formDataNoChecklistId.append("checklist_file", ssFile);
      formData.append("fileType", "Image");
      formDataNoChecklistId.append("fileType", "Image");
      formData.append("withAnswer", "True");
      formDataNoChecklistId.append("withAnswer", "True");
      formData.append("survey", id);
      formDataNoChecklistId.append("survey", id);
      formData.append("name", fileName);
      formDataNoChecklistId.append("name", fileName2);

      formData.append("lat", lat.toFixed(6));
      formDataNoChecklistId.append("lat", lat.toFixed(6));

      formData.append("lng", lng.toFixed(6));
      formDataNoChecklistId.append("lng", lng.toFixed(6));

      formData.append("createdBy", localStorage.getItem("vdi_User_Id"));
      let response = await axiosPostData("CheckListAttachment/", formData);

      if (response) {
        console.log("response", response);

        const response2 = await axiosPostData(
          "CheckListAttachment/",
          formDataNoChecklistId
        );
        console.log("response2", response2);
        const reqPayload = {
          customer_id: localData.customer_id,
          survey_id: id,
          user: localData.user,
          section_id: secId,
        };
        fetchSurveySectionItems(reqPayload);
        toast.success("Screenshot Captured !", {
          autoClose: 2000,
          theme: "light",

        });
      }

      iconToHide.classList.remove("d-none");
    } else {
      toast.error(
        "Unable to capture location, please enable browser location, refresh & try again !",
        {
          autoClose: 4000,
          theme: "light",

        }
      );
    }
  };

  function handleChecklistItemResult(para1, e, sec, item, val) {
    if (para1 === "radio") {
      console.log("para1", para1);
      console.log("sec", sec);
      console.log("item", item);
      console.log("val", val);
      console.log("e.target.value", e.target.value);

      setAnswer(e.target.value);
    } else if (para1 === "text") {
      console.log("para1", para1);
      console.log("sec", sec);
      console.log("item", item);
      console.log(e.target.value);
      setComments(e.target.value);
    }
  }

  const handleAddingPhoto = async (event) => {
    setPhotosVideos({ ...photosVideos, loading: true, data: [] });

    getChecklistItemAttachment({
      survey: id,
      withSurveyOnly: "False",
      withTable: "False",
    }).then(
      (res) => {
        console.log("res", res);
        setPhotosVideos({ ...photosVideos, loading: false, data: res.data });
      },
      (err) => {
        console.log("err", err);
      }
    );
    // setPhotosVideos(response);
  };

  const fetchVideoRecording = async () => {
    setRecordingVideos({ ...recordingVideos, loading: true, data: [] });
    let resFromRecording = await getData(`SurveyRecordings/?survey=${id}`);

    setRecordingVideos({
      ...recordingVideos,
      loading: false,
      data: resFromRecording,
    });
  };

  const AddData = async (event) => {
    for (let i = 0; i < preview.length; i++) {
      let item = preview[i];
      const fileForConversion = item.checklist_file.split("?");
      let response = await fetch(fileForConversion[0], {
        headers: {},
      });
      console.log("res",response)
      let blob = await response.blob();
      blob = new File([blob], `${item.name}`, {
        type:
          item.fileType == "Image"
            ? "image/jpeg"
            : item.fileType == "Video"
              ? "video/mp4"
              : "",
      });
      console.log("blod",blob)
      console.log("filtype",item.fileType)
      
      const formData = new FormData();
      formData.append("checklist_file", blob);
      formData.append("checkListItem", selectedItem.Id);
      formData.append("fileType", item.fileType);
      formData.append("withAnswer", "True");
      formData.append("survey", id);
      formData.append("name", item.name);
      if (item.lat !== null) {
        formData.append("lat", item.lat);
      }
      if (item.long !== null) {
        formData.append("long", item.long);
      }
      if (item.createdBy !== null) {
        formData.append("createdBy", item.createdBy.Id);
      }
      console.log("formata",formData)
      let res = await uploadChecklistItemAttachment(formData);
      console.log(res);
    }

    toast.success("Attachment Uploaded Successfully !", {
      autoClose: 3000,
      theme: "light",

    });

    AttachmentToggle();
    setPreview([]);
    const reqPayload = {
      customer_id: localData.customer_id,
      survey_id: id,
      user: localData.user,
      section_id: selectedSection.id,
    };
    fetchSurveySectionItems(reqPayload);
  };

  const linkRecordingtoChecklist = async (event) => {
    let params = {
      checkListItem: selectedItem?.Id,
      sid: selectedRecordedVideo?.sid,
      userId: Math.floor((Date.now() * Math.random()) / 10000),
      survey: id,
      stopped_at: selectedRecordedVideo?.stopped_at,
      url: selectedRecordedVideo?.url,
    };
    let response = await postData("SurveyRecordings/", params, "Bearer ");
    if (response) {
      console.log(response);
      // dispatch({ type: WATCH_SURVEY_LIST });
      let resFromRecording = await getData(
        `SurveyRecordings/?survey=${id}&checkListItem=${selectedItem.Id}`
      );

      const tempRes = resFromRecording.filter((val) => {
        return val.checkListItem === selectedItem.Id;
      });
      console.log("tempRes", tempRes);

      recordingToggle(event);
      setSelectedRecordedVideo("");
      const reqPayload = {
        customer_id: localData.customer_id,
        survey_id: id,
        user: localData.user,
        section_id: selectedSection.id,
      };
      fetchSurveySectionItems(reqPayload);
    }
  };

  const linkRecordings = async (event) => {
    let resFromRecording = await getData(`SurveyRecordings/?survey=${id}`);

    const tempRes = resFromRecording.filter((val) => {
      return val.checkListItem === null;
    });
    console.log("tempRes", tempRes);
    setLinkRecordingVideos(tempRes);
  };

  function countAnswersFilled(data) {
    // console.log("data", data);
    var temp = 0;

    for (let i = 0; i < data?.length; i++) {
      if (data[i].test_pass == true) {
        temp = temp + 1;
      }
    }
    return temp;
  }

  async function saveAnswerAndMoveNext(
    sec,
    item,
    presentSections,
    presentItems,
    secIndex,
    ItemIndex
  ) {
    console.log("sec", sec);
    console.log("item", item);
    console.log("presentSections", presentSections);
    console.log("presentItems", presentItems);
    console.log("secIndex", secIndex);
    console.log("ItemIndex", ItemIndex);

    if (answer.length === 0) {
      toast.error("Please Select Answer !", {
        autoClose: 3000,
        theme: "light",

      });
      return;
    }

    if (item.photos === "Yes") {
      const temp = item.check_list_attachment.filter((val, i) => {
        return (
          val.survey !== null &&
          val.checkListItem !== null &&
          val.fileType == "Image"
        );
      });
      if (temp.length === 0) {
        toast.error("Please Upload Image Attachment !", {
          autoClose: 3000,
          theme: "light",

        });
        return;
      }
    }

    if (item.videos === "Yes") {
      const temp = item.check_list_attachment.filter((val, i) => {
        return (
          val.survey !== null &&
          val.checkListItem !== null &&
          val.fileType == "Video"
        );
      });
      if (temp.length === 0) {
        toast.error("Please Upload Video Attachment !", {
          autoClose: 3000,
          theme: "light",

        });
        return;
      }
    }

    if (item.comments === "Yes") {
      if (comments.length === 0) {
        toast.error("Please Enter Comments !", {
          autoClose: 3000,
          theme: "light",

        });
        return;
      }
    } else {
      const methodologyList =
        sectionList.data.SOP.methodology

      console.log("methodologyList", methodologyList);
      console.log("ncList.data", ncList.data);
      console.log("answer", answer);
      if (ncList.data.length > 0) {
        if (methodologyList.type == "Ratings") {
          if (parseInt(answer) <= ncList.data[0].ncRating) {
            if (comments.length === 0) {
              toast.error("Comment is required !", {
                autoClose: 3000,
                theme: "light",

              });
              return;
            }
          }
        } else {
          const nc = ncList.data[0].ncList.includes(answer);
          if (nc == true) {
            if (comments.length === 0) {
              toast.error("Comment is required !", {
                autoClose: 3000,
                theme: "light",

              });
              return;
            }
          }
        }
      }
    }

    if (ItemIndex + 1 < presentItems.length) {
      console.log("---------inside if----------");
      await submitAnswer(item);
      const reqPayload = {
        customer_id: localData.customer_id,
        survey_id: id,
        user: localData.user,
        section_id: sec.id,
      };

      if (NCFilter == true) {
        fetchNCItems(sec.id)
      } else {
        await fetchSurveySectionItems(reqPayload);
      }

      await fetchSurveyStatus();
      await toggle2(ItemIndex + 2);
      executeScroll();
    } else {
      console.log("---------inside else----------");
      if (secIndex + 1 < presentSections.checklist.checklist_sections.length) {
        await submitAnswer(item);
        await toggle(
          secIndex + 2,
          presentSections.checklist.checklist_sections[secIndex + 1].id
        );
        await fetchSurveyStatus();
        await toggle2(1);
        executeScroll();
      } else {
        await submitAnswer(item);
        await fetchSurveyStatus();
        const reqPayload = {
          customer_id: localData.customer_id,
          survey_id: id,
          user: localData.user,
          section_id: sec.id,
        };
        // await fetchSurveySectionItems(reqPayload);
        if (NCFilter == true) {
          fetchNCItems(sec.id)
        } else {
          await fetchSurveySectionItems(reqPayload);
        }

        console.log(
          "checklistItemList.data[ItemIndex].checklist_item_result[checklistItemList.data[ItemIndex].checklist_item_result.length - 1].values",
          checklistItemList.data[ItemIndex].checklist_item_result[
            checklistItemList.data[ItemIndex].checklist_item_result.length - 1
          ].values
        );
        setAnswer(
          checklistItemList.data[ItemIndex].checklist_item_result[
            checklistItemList.data[ItemIndex].checklist_item_result.length - 1
          ].values
        );
        setComments(
          checklistItemList.data[ItemIndex].checklist_item_result[
            checklistItemList.data[ItemIndex].checklist_item_result.length - 1
          ].remarks
        );
        executeScroll();
      }
    }
  }

  function movePrev(
    sec,
    item,
    presentSections,
    presentItems,
    secIndex,
    ItemIndex
  ) {
    console.log("sec", sec);
    console.log("item", item);
    console.log("presentSections", presentSections);
    console.log("presentItems", presentItems);
    console.log("secIndex", secIndex);
    console.log("ItemIndex", ItemIndex);

    if (ItemIndex === 0) {
      // console.log("change to prev section");
      if (secIndex !== 0) {
        toggle(
          secIndex,
          presentSections.checklist.checklist_sections[secIndex].id
        );
        toggle2(presentItems.length);
      } else {
        console.log("End of sections and items");
      }
    } else {
      toggle2(ItemIndex);
    }
  }

  async function submitAnswer(data) {
    console.log("data", data);
    let params = {
      checkListItem: data.Id,
      values: answer,
      remarks: comments,
      createdBy: localStorage.getItem("vdi_User_Id"),
      survey: id,
    };
    console.log("params", params);

    let response = await postData("CheckListOutcome/", params, "Bearer ");
    if (response) {
      toast.success("Answer Submitted Successfully !", {
        autoClose: 3000,
        theme: "light",

      });
      setAnswer("");
      setComments("");
    }
  }

  const handleProceed = async () => {
    var response;
    if (sectionList.data.status != "Signed") {
      let params = {
        Id: id,
        status: "Completed",
      };
      response = await putData("SurveyApi/", params);
      if (response) {
        toast.success("Survey Completed...", {
          autoClose: 3000,
          theme: "light",

        });
      }
    }
    console.log("response", response);
    navigate(
      `${process.env.PUBLIC_URL}/surveyList/surveyDashboard/surveySectionList/previewpage/v2/${id}/${cid}`,
      { state: {status:"Signed", data:response} }
    );
  };

  function updateFilename() {
    console.log("filename2", filename2);
    console.log("imgPreview", imgPreview);
    const reqPayload = {
      Id: imgPreview.data.Id,
      name: filename2,
    };

    console.log("reqPayload", reqPayload);
    var formdata = new FormData();

    for (var key in reqPayload) {
      formdata.append(key, reqPayload[key]);
    }
    if (filename2.length > 0) {
      setFilenameErr2("");
      console.log("selectedSection", selectedSection);
      renameChecklistItemAttachment(formdata).then(
        (res) => {
          console.log("res", res);
          toast.success("Attachment renamed successfully", {
            autoClose: 3000,
            theme: "light",

          });
          toggle8();
          console.log("selectedSection", selectedSection);
          const reqPayload = {
            customer_id: localData.customer_id,
            survey_id: id,
            user: localData.user,
            section_id: selectedSection.id,
          };
          fetchSurveySectionItems(reqPayload);
        },
        (err) => {
          console.log("err", err);
          toast.error("Failed to rename attachment", {
            autoClose: 3000,
            theme: "light",

          });
        }
      );
    } else {
      setFilenameErr2("Required");
    }
  }

  async function calUnreadMsgCount() {
    getAuth();

    await ref
      .where("surveyId", "==", parseInt(id))
      .where(
        "from",
        "!=",
        GuestVideoCall === true ? guestUserdata.email : emailAddress?.emailId
      )
      // .orderBy("time")
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          console.log(doc);

          const msgData = doc.data();
          // items.push(doc.data());
          // items.push(doc);

          if (
            msgData.seenBy.includes(
              GuestVideoCall === true
                ? guestUserdata.email
                : emailAddress?.emailId
            )
          ) {
          } else {
            items.push(msgData);
          }
        });
        console.log("items", items);

        // for (let i = 0; i < items.length; i++) {
        //   if (items[i].seenBy.includes(GuestVideoCall === true ? guestUserdata.email : emailAddress?.emailId)) {

        //   } else {
        //     finalVal.push(items[i]);
        //   }
        // }

        console.log("items.length", items.length);

        setUnreadMsgCount(items.length);
      });
  }

  function sortReferences(data) {
    const temp = data.filter((val) => {
      return val.withAnswer == false && val.withSurveyOnly == false;
    });
    return temp;
  }


  function fetchNCSections() {
    get_nonconformance_sections({
      customer_id: localData.customer_id,
      survey_id: id
    }).then((res) => {
      console.log("res", res);

      const tempSectionList = { ...sectionList };
      tempSectionList.data.checklist.checklist_sections = res.data.SurveyData

      setSectionList(tempSectionList)
    }, (err) => {
      console.log("err", err);
    })
  }
// show docs & table in survey
  useEffect(()=>{
    fetchDocs()
    fetchTables()
  },[id])
  async function fetchDocs() {
   
    const payloadAttForSurvey = {
      survey: id,
      withSurveyOnly: "True",
      withTable: "False",
      isRef: "False",
    };

    await getChecklistItemAttachmentv3(payloadAttForSurvey).then(
      (res) => {
        console.log("res", res);
             if(res.data.length>0){
              setShowdoc(true);
             }
             else{
               setShowdoc(false)
             }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchTables() {
    var reqPayload = {
      pageNo: 1,
      entriesPerPage: 1000,
      customer_id: localData.customer_id,
      checklist: cid,
      survey: id,
      withSurvey: "True",
    };
    getCheckListTable(reqPayload).then(
      (res) => {
        console.log("res", res);
        if (res.data.payload.length > 0) {
          setShowtable(true);
        } else {
          var payload = {
            pageNo: 1,
            entriesPerPage: 1000,
            customer_id: localData.customer_id,
            checklist: cid,
            withSurvey: "False",
          };
          getCheckListTable(payload).then(
            (res) => {
              console.log("res", res);
              if (res.data.payload.length > 0) {
                
                if (res.data.payload[0].file.length > 0) {
                  setShowtable(true)
                }
              }
            },
            (err) => {
              console.log("err", err);
              setShowtable(false);
            }
          );
        }
      },
      (err) => {
        console.log("err", err);
        setShowtable(false)
      }
    );
  }



  return (
    <React.Fragment>
      <div className="conduct_survey py-4 fontFamily">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div
                className="bg-primary-blue d-flex justify-content-between"
                id="card_title"
              >
                {sectionList.loading ? null : (
                  <React.Fragment>
                    <p className="mb-0 f-24">
                      <span
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="text-light me-2"
                        style={{ fontSize: "20px", cursor: "pointer" }}
                      >
                        <i className="fa fa-arrow-circle-left"></i>
                      </span>
                      {`${sectionList.data.vessel?.name}`}
                    </p>
                    <p className="mb-0 f-24">{`Survey Name: ${sectionList.data.checklist?.name}`}</p>
                    <p className="mb-0 f-24">{`Status: ${sectionList.data.status}`}</p>
                  </React.Fragment>
                )}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6 mb-4 mb-md-0">
                    {sectionList.data.verifier_activity?.length > 0 ? (
                      <>
                        <label htmlFor="vc" className="f-16 f-w-500">
                          Verifier's Comment
                        </label>
                        <textarea
                          id="vc"
                          type={"text"}
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#2264AB",
                          }}
                          className="form-control"
                          readOnly
                          value={
                            sectionList.data.verifier_activity[sectionList.data.verifier_activity.length - 1]?.comments
                          }
                          rows="2"
                        ></textarea>
                      </>
                    ) : null}
                  </div>

                  <div className="col-12 d-flex justify-content-end">

                    <button
                      className="btn btn-primary-blue"
                      onClick={checkTotalCustSecs}
                    >
                      Start A Call
                      <i className="fa fa-video-camera ms-2"></i>
                    </button>


                    <button
                      onClick={() => toggleLinkModal()}
                      className="btn btn-primary-blue ms-2"
                    >
                      Invite Participants
                      <i className="fa fa-link ms-2"></i>
                    </button>

                    {location.state.data.surveyType !== "Sire" ?
                      <div className="toogle_switch ms-2 d-flex">
                        <div>
                          <label className="f-w-600">Non Conformance : </label>
                        </div>
                        <label className="switch ms-2">
                          <input type="checkbox"
                            onChange={(e) => {
                              console.log("e.target.checked", e.target.checked);
                              if (e.target.checked) {
                                setNCFilter(true);
                                fetchNCSections();
                                setIsOpen(0);
                              } else {
                                setNCFilter(false);
                                fetchSurveySections({
                                  customer_id: localData.customer_id,
                                  Id: id,
                                  user: localData.user,
                                });
                                setIsOpen(0);
                              }
                            }} />
                          <span className="slider round"></span>
                        </label>
                        {/* <button
                      onClick={() => { fetchNCSections() }}
                      className="btn btn-primary-blue ms-2"
                    >View Non Conformance
                    </button> */}
                      </div>
                      : null}




                  </div>

                  {call ? (
                    <div className="col-12 col-md-6 ">
                      <VideoApp
                        call={call}
                        setOpen={setOpen}
                        setCall={setCall}
                        chanelName={id}
                      // token={token}
                      />
                    </div>
                  ) : null}



                  <div
                    style={{ height: "100vh", overflow: "hidden auto" }}
                    className={call ? "col-12 col-md-6 mt-4" : "col-12 mt-4"}
                  >
                   { showdoc&&<OnlySurveyDocs basic_settings={basic_settings} />}
                    <SurveyForm />
                 { showtable&&  <FormsOfSurvey2 />}

                    {sectionList.loading ? (
                      <div className="loader-box justify-content-center">
                        <div className="loader">
                          <div className="line bg-dark"></div>
                          <div className="line bg-dark"></div>
                          <div className="line bg-dark"></div>
                          <div className="line bg-dark"></div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div
                          className="default-according style-1"
                          id="accordionoc"
                        >
                          {sectionList.data.checklist?.checklist_sections?.map(
                            (sec, ind) => (
                              <Card key={ind}>
                                <CardHeader className="bg-vedam p-2">
                                  {/* <h5 className="mb-0"> */}
                                  <Button
                                    style={{ position: "relative" }}
                                    as={Card.Header}
                                    className="btn btn-link btn-vedam txt-white p-0 "
                                    onClick={() => {
                                      toggle(ind + 1, sec.id);
                                      setIsOpen2(null);
                                    }}
                                  >
                                    {/* <i className="icofont icofont-briefcase-alt-2"></i> */}
                                    <p className="mb-0 f-16 me-5">
                                      {" "}
                                      {`${sec.sectionId}. ${sec.sectionName}`}
                                    </p>

                                    {location.state.data.surveyType !== "Sire" && NCFilter == false ?
                                      <p
                                        className="mb-0 f-16"
                                        style={{
                                          position: "absolute",
                                          right: "6px",
                                          top: "50%",
                                          transform: "translate(0, -50%)",
                                        }}
                                      >
                                        {" "}
                                        {`${countAnswersFilled(
                                          surveyItemStatus.data[ind]?.checklist
                                        )}/${surveyItemStatus.data[ind]?.checklist
                                          .length
                                          }`}
                                      </p>
                                      :
                                      null
                                    }

                                  </Button>
                                  {/* </h5> */}
                                </CardHeader>
                                <Collapse isOpen={isOpen === ind + 1}>
                                  <CardBody className="p-3">
                                    <div
                                      className="default-according style-1"
                                      id="accordionoc"
                                    >
                                      {checklistItemList.loading ? (
                                        <div className="loader-box justify-content-center">
                                          <div className="loader">
                                            <div className="line bg-dark"></div>
                                            <div className="line bg-dark"></div>
                                            <div className="line bg-dark"></div>
                                            <div className="line bg-dark"></div>
                                          </div>
                                        </div>
                                      ) : (

                                        location.state.data.surveyType == "Sire" ?
                                          checklistItemList?.data?.map((item, i) => (

                                            <Sire_flow
                                              call={call}
                                              item={item}
                                              value={i + 1}
                                              fetchSurveySectionItems={fetchSurveySectionItems}
                                              sectionDetails={sec}
                                              myRef={myRef}
                                              fetchSurveyStatus={fetchSurveyStatus}
                                            // sire_outcome={sire_outcome[i]}
                                            />
                                          ))

                                          :

                                          checklistItemList?.data?.map(
                                            (item, i) => (
                                              <Card key={i} ref={myRef}>
                                                <CardHeader className="bg-light p-2">
                                                  <h5 className="mb-0">
                                                    <Button
                                                      as={Card.Header}
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                      className="btn btn-link txt-white p-0 d-flex"
                                                      color="light"
                                                      onClick={() =>
                                                        toggle2(i + 1)
                                                      }
                                                    >
                                                      <p className="mb-0 f-16 me-5">
                                                        {" "}
                                                        {`${item.SrNo}. ${item.section}`}
                                                      </p>

                                                      {location.state.data.surveyType !== "Sire" ?
                                                        <span
                                                          className="ms-5"
                                                          style={{
                                                            height: "15px",
                                                            width: "15px",
                                                            position: "absolute",
                                                            right: "10px",
                                                            top: "50%",
                                                            transform:
                                                              "translate(0, -50%)",
                                                            borderRadius: "50%",
                                                            background:
                                                              item.answer_pass &&
                                                                item.comment_pass &&
                                                                item.image_pass &&
                                                                item.video_pass
                                                                ? "#51EE27"
                                                                : "red",
                                                          }}
                                                        ></span>
                                                        : null}
                                                    </Button>
                                                  </h5>
                                                </CardHeader>
                                                <Collapse
                                                  isOpen={isOpen2 === i + 1}
                                                >
                                                  <CardBody className="p-3">
                                                    <div className="radio radio-primary">
                                                      <div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
                                                        {JSON.parse(
                                                          sectionList.data.SOP
                                                            .methodology.values
                                                        ).map((mval, proind) => (
                                                          <div
                                                            className="radio radio-primary"
                                                            key={Math.ceil(
                                                              Math.random() *
                                                              100000
                                                            )}
                                                          >
                                                            <input
                                                              id={
                                                                "edo-ani" + mval
                                                              }
                                                              type="radio"
                                                              defaultChecked={
                                                                mval == answer
                                                              }
                                                              // onClick={() => handleAnswerClick(mval)}
                                                              onChange={(e) => {
                                                                handleChecklistItemResult(
                                                                  "radio",
                                                                  e,
                                                                  sec,
                                                                  item
                                                                );
                                                              }}
                                                              name={
                                                                "name_answer_" +
                                                                ind +
                                                                "_" +
                                                                i
                                                              }
                                                              value={mval}
                                                            />
                                                            <label
                                                              htmlFor={
                                                                "edo-ani" + mval
                                                              }
                                                            >
                                                              {mval}
                                                            </label>
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>

                                                    {sortReferences(
                                                      item.check_list_attachment
                                                    ).length > 0 ? (
                                                      <div className="references mt-4">
                                                        <Card>
                                                          <CardHeader className="bg-dark">
                                                            <h5 className="mb-0">
                                                              <Button
                                                                as={Card.Header}
                                                                className="btn btn-link txt-white p-0"
                                                                color="dark"
                                                                onClick={() =>
                                                                  rtoggle(1)
                                                                }
                                                              >
                                                                View Reference
                                                                photos / videos /
                                                                pdf / ms word /
                                                                excel files for
                                                                surveyor
                                                              </Button>
                                                            </h5>
                                                          </CardHeader>
                                                          <Collapse
                                                            isOpen={risOpen === 1}
                                                          >
                                                            <CardBody>
                                                              {sortReferences(
                                                                item.check_list_attachment
                                                              ).length > 0 ? (
                                                                <Slider
                                                                  {...basic_settings}
                                                                  className="mb-2 mb-md-0"
                                                                >
                                                                  {sortReferences(
                                                                    item.check_list_attachment
                                                                  ).map(
                                                                    (img, imgi) =>
                                                                      img.withAnswer ===
                                                                        false ? (
                                                                        img.fileType ==
                                                                          "Image" ? (
                                                                          <img
                                                                            id="reference_img"
                                                                            onClick={() =>
                                                                              window
                                                                                .open(
                                                                                  img.checklist_file,
                                                                                  "_blank"
                                                                                )
                                                                                .focus()
                                                                            }
                                                                            key={
                                                                              imgi
                                                                            }
                                                                            className="p-2 cursor_pointer"
                                                                            src={
                                                                              img.checklist_file
                                                                            }
                                                                            alt=""
                                                                            height={
                                                                              "150"
                                                                            }
                                                                            width={
                                                                              "150"
                                                                            }
                                                                          />
                                                                        ) : img.fileType ===
                                                                          "Document" ? (
                                                                          <img
                                                                            id="reference_img"
                                                                            onClick={() =>
                                                                              window
                                                                                .open(
                                                                                  img.checklist_file,
                                                                                  "_blank"
                                                                                )
                                                                                .focus()
                                                                            }
                                                                            key={
                                                                              imgi
                                                                            }
                                                                            className="p-2 cursor_pointer"
                                                                            src={
                                                                              fileimg
                                                                            }
                                                                            alt=""
                                                                            height={
                                                                              "150"
                                                                            }
                                                                            width={
                                                                              "150"
                                                                            }
                                                                          />
                                                                        ) : null
                                                                      ) : null
                                                                  )}
                                                                </Slider>
                                                              ) : (
                                                                "no attachments"
                                                              )}
                                                            </CardBody>
                                                          </Collapse>
                                                        </Card>
                                                      </div>
                                                    ) : null}

                                                    <div className="photo_attachments">
                                                      <Card>
                                                        <CardHeader className="bg-dark">
                                                          <h5 className="mb-0">
                                                            <Button
                                                              as={Card.Header}
                                                              className="btn btn-link p-0"
                                                              color="dark"
                                                            >
                                                              <span
                                                                style={{
                                                                  color: "white",
                                                                }}
                                                              >
                    
                                                                Uploaded Photos
                                                                {item.photos ===
                                                                  "Yes" ? (
                                                                  <span className="text-danger">
                                                                    {" "}
                                                                    *
                                                                  </span>
                                                                ) : null}
                                                                <Camera
                                                                  className="cursor_pointer camera_icon_survey ms-2"
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                  }}
                                                                  height={"20px"}
                                                                  width={"20px"}
                                                                  onClick={() =>
                                                                    checkLocationPermission(
                                                                      sec.id,
                                                                      item
                                                                    )
                                                                  }
                                                                />
                                                              </span>
                                                            </Button>
                                                          </h5>
                                                        </CardHeader>
                                                        <Collapse isOpen={true}>
                                                          <CardBody>
                                                            {item.check_list_attachment.filter(
                                                              (fval) => {
                                                                return (
                                                                  fval.fileType ===
                                                                  "Image" &&
                                                                  fval.withAnswer ===
                                                                  true &&
                                                                  fval.checkListItem !==
                                                                  null
                                                                );
                                                              }
                                                            ).length > 0 ? (
                                                              <Slider
                                                                {...basic_settings}
                                                                className="mb-2 mb-md-0"
                                                              >
                                                                {item.check_list_attachment.map(
                                                                  (img, i) =>
                                                                    img.fileType ===
                                                                      "Image" &&
                                                                      img.withAnswer ===
                                                                      true &&
                                                                      img.checkListItem !==
                                                                      null ? (
                                                                      <div
                                                                        id="attachment_parent"
                                                                        key={i}
                                                                      >
                                                                        <div className="image_attachments m-2">
                                                                          <img
                                                                            onClick={() =>
                                                                              window
                                                                                .open(
                                                                                  img.checklist_file,
                                                                                  "_blank"
                                                                                )
                                                                                .focus()
                                                                            }
                                                                            style={{
                                                                              borderRadius:
                                                                                "20px",
                                                                            }}
                                                                            className="cursor_pointer"
                                                                            src={
                                                                              img.checklist_file
                                                                            }
                                                                            alt=""
                                                                            height={
                                                                              "150"
                                                                            }
                                                                            width={
                                                                              "100%"
                                                                            }
                                                                          />
                                                                          <div
                                                                            onClick={() => {
                                                                              toggle8();
                                                                              setImgPreview(
                                                                                {
                                                                                  ...imgPreview,
                                                                                  type: "image",
                                                                                  file: img.checklist_file,
                                                                                  data: img,
                                                                                }
                                                                              );
                                                                              setFilename2(
                                                                                img.name
                                                                              );
                                                                              setSelectedSection(
                                                                                sec
                                                                              );
                                                                            }}
                                                                            className="overlay d-flex align-items-center justify-content-center"
                                                                          >
                                                                            <Eye
                                                                              style={{
                                                                                color:
                                                                                  "white",
                                                                              }}
                                                                              height={
                                                                                "20px"
                                                                              }
                                                                              width={
                                                                                "20px"
                                                                              }
                                                                            />
                                                                          </div>

                                                                          <div className="date_overlay">
                                                                            <p
                                                                              className="mb-0"
                                                                              style={{
                                                                                lineHeight:
                                                                                  "1",
                                                                              }}
                                                                            >
                                                                              {new Date(
                                                                                img.created
                                                                              ).toLocaleString()}
                                                                              <br />
                                                                              {
                                                                                img.name
                                                                              }
                                                                            </p>
                                                                          </div>
                                                                        </div>

                                                                        <div
                                                                          style={{
                                                                            background:
                                                                              "white",
                                                                            height:
                                                                              "18px",
                                                                            width:
                                                                              "18px",
                                                                            position:
                                                                              "absolute",
                                                                            borderRadius:
                                                                              "50%",
                                                                            top: "2px",
                                                                            right:
                                                                              "2px",
                                                                          }}
                                                                        >
                                                                          <XCircle
                                                                            onClick={() =>
                                                                              deletePhoto(
                                                                                img,
                                                                                sec.id
                                                                              )
                                                                            }
                                                                            className="cursor_pointer"
                                                                            style={{
                                                                              color:
                                                                                "red",
                                                                            }}
                                                                            height={
                                                                              "18px"
                                                                            }
                                                                            width={
                                                                              "18px"
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    ) : null
                                                                )}
                                                              </Slider>
                                                            ) : (
                                                              <p className="text-danger text-center">
                                                                No Attachments
                                                                Available
                                                              </p>
                                                            )}
                                                          </CardBody>
                                                        </Collapse>
                                                      </Card>
                                                    </div>

                                                    <div className="video_attachments">
                                                      <Card>
                                                        <CardHeader className="bg-dark">
                                                          <h5 className="mb-0">
                                                            <Button
                                                              as={Card.Header}
                                                              className="btn btn-link p-0"
                                                              color="dark"
                                                            >
                                                              <span
                                                                style={{
                                                                  color: "white",
                                                                }}
                                                              >
                                                               
                                                                Uploaded Videos
                                                                {item.videos ===
                                                                  "Yes" ? (
                                                                  <span className="text-danger">
                                                                    {" "}
                                                                    *
                                                                  </span>
                                                                ) : null}
                                                                <Video
                                                                  className="cursor_pointer camera_icon_survey ms-2"
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                  }}
                                                                  height={"20px"}
                                                                  width={"20px"}
                                                                />
                                                              </span>
                                                            </Button>
                                                          </h5>
                                                        </CardHeader>
                                                        <Collapse isOpen={true}>
                                                          <CardBody>
                                                            {item.check_list_attachment.filter(
                                                              (fval) => {
                                                                return (
                                                                  fval.fileType ===
                                                                  "Video" &&
                                                                  fval.withAnswer ===
                                                                  true &&
                                                                  fval.checkListItem !==
                                                                  null
                                                                );
                                                              }
                                                            ).length > 0 ? (
                                                              <Slider
                                                                {...basic_settings}
                                                                className="mb-2 mb-md-0"
                                                              >
                                                                {item.check_list_attachment.map(
                                                                  (img, i) =>
                                                                    img.fileType ===
                                                                      "Video" &&
                                                                      img.withAnswer ===
                                                                      true &&
                                                                      img.checkListItem !==
                                                                      null ? (
                                                                      <div
                                                                        id="attachment_parent"
                                                                        key={i}
                                                                      >
                                                                        <div className="image_attachments m-2">
                                                                          <video
                                                                            title="video"
                                                                            // onClick={() => window.open(img.checklist_file, '_blank').focus()}
                                                                            controls
                                                                            style={{
                                                                              borderRadius:
                                                                                "20px",
                                                                            }}
                                                                            className="cursor_pointer"
                                                                            src={
                                                                              img.checklist_file
                                                                            }
                                                                            alt=""
                                                                            height={
                                                                              "150"
                                                                            }
                                                                            width={
                                                                              "100%"
                                                                            }
                                                                          />
                                                                          <div
                                                                            onClick={() => {
                                                                              toggle8();
                                                                              setImgPreview(
                                                                                {
                                                                                  ...imgPreview,
                                                                                  type: "video",
                                                                                  file: img.checklist_file,
                                                                                  data: img,
                                                                                }
                                                                              );
                                                                              // setImgPreview({ imgPreview, type: "video", file: img.checklist_file });
                                                                              setFilename2(
                                                                                img.name
                                                                              );
                                                                              setSelectedSection(
                                                                                sec
                                                                              );
                                                                            }}
                                                                            className="overlay d-flex align-items-center justify-content-center"
                                                                          >
                                                                            <Eye
                                                                              style={{
                                                                                color:
                                                                                  "white",
                                                                              }}
                                                                              height={
                                                                                "20px"
                                                                              }
                                                                              width={
                                                                                "20px"
                                                                              }
                                                                            />
                                                                          </div>

                                                                          <div className="date_overlay">
                                                                            <p
                                                                              className="mb-0"
                                                                              style={{
                                                                                lineHeight:
                                                                                  "1",
                                                                              }}
                                                                            >
                                                                              {new Date(
                                                                                img.created
                                                                              ).toLocaleString()}
                                                                              <br />
                                                                              {
                                                                                img.name
                                                                              }
                                                                            </p>
                                                                          </div>
                                                                        </div>

                                                                        <div
                                                                          style={{
                                                                            background:
                                                                              "white",
                                                                            height:
                                                                              "18px",
                                                                            width:
                                                                              "18px",
                                                                            position:
                                                                              "absolute",
                                                                            borderRadius:
                                                                              "50%",
                                                                            top: "2px",
                                                                            right:
                                                                              "2px",
                                                                          }}
                                                                        >
                                                                          <XCircle
                                                                            onClick={() =>
                                                                              deletePhoto(
                                                                                img,
                                                                                sec.id
                                                                              )
                                                                            }
                                                                            className="cursor_pointer"
                                                                            style={{
                                                                              color:
                                                                                "red",
                                                                            }}
                                                                            height={
                                                                              "18px"
                                                                            }
                                                                            width={
                                                                              "18px"
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    ) : null
                                                                )}
                                                              </Slider>
                                                            ) : (
                                                              <p className="text-danger text-center">
                                                                No Attachments
                                                                Available
                                                              </p>
                                                            )}
                                                          </CardBody>
                                                        </Collapse>
                                                      </Card>
                                                    </div>

                                                    <div className="call_recordings">
                                                      <Card>
                                                        <CardHeader className="bg-dark">
                                                          <h5 className="mb-0">
                                                            <Button
                                                              as={Card.Header}
                                                              className="btn btn-link p-0"
                                                              color="dark"
                                                            >
                                                              <span
                                                                style={{
                                                                  color: "white",
                                                                }}
                                                              >
                                                                Linked Recordings
                                                                
                                                              </span>
                                                            </Button>
                                                          </h5>
                                                        </CardHeader>
                                                        <Collapse isOpen={true}>
                                                          <CardBody>
                                                            {item
                                                              .checklist_item_recording
                                                              .length > 0 ? (
                                                              <Slider
                                                                {...basic_settings}
                                                                className="mb-2 mb-md-0"
                                                              >
                                                                {item.checklist_item_recording.map(
                                                                  (img, i) => (
                                                                    <div
                                                                      id="attachment_parent"
                                                                      key={i}
                                                                    >
                                                                      <div className="image_attachments m-2">
                                                                        <video
                                                                          title="video"
                                                                          // onClick={() => window.open(img.checklist_file, '_blank').focus()}
                                                                          controls
                                                                          style={{
                                                                            borderRadius:
                                                                              "20px",
                                                                          }}
                                                                          className="cursor_pointer"
                                                                          src={
                                                                            img.url
                                                                          }
                                                                          alt=""
                                                                          height={
                                                                            "150"
                                                                          }
                                                                          width={
                                                                            "100%"
                                                                          }
                                                                        />
                                                                        <div
                                                                          onClick={() => {
                                                                            toggle8();
                                                                            setImgPreview(
                                                                              {
                                                                                imgPreview,
                                                                                type: "video",
                                                                                file: img.url,
                                                                              }
                                                                            );
                                                                          }}
                                                                          className="overlay d-flex align-items-center justify-content-center"
                                                                        >
                                                                          <Eye
                                                                            style={{
                                                                              color:
                                                                                "white",
                                                                            }}
                                                                            height={
                                                                              "20px"
                                                                            }
                                                                            width={
                                                                              "20px"
                                                                            }
                                                                          />
                                                                        </div>

                                                                        {/* <div className='date_overlay'>
                                                                                                                                {new Date(img.created).toLocaleString()}
                                                                                                                            </div> */}
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                )}
                                                              </Slider>
                                                            ) : (
                                                              <p className="text-danger text-center">
                                                                No Recorded Videos
                                                                Available
                                                              </p>
                                                            )}
                                                          </CardBody>
                                                        </Collapse>
                                                      </Card>
                                                    </div>

                                                    <div className="call_recordings">
                                                      <Card>
                                                        <CardHeader className="bg-dark">
                                                          <h5 className="mb-0">
                                                            <Button
                                                              as={Card.Header}
                                                              className="btn btn-link p-0"
                                                              color="dark"
                                                            >
                                                              <span
                                                                style={{
                                                                  color: "white",
                                                                }}
                                                              >
                                                                Comments /
                                                                Observations
                                                                {item.comments ===
                                                                  "Yes" ? (
                                                                  <span className="text-danger">
                                                                    {" "}
                                                                    *
                                                                  </span>
                                                                ) : null}
                                                              </span>
                                                            </Button>
                                                          </h5>
                                                        </CardHeader>
                                                        <Collapse isOpen={true}>
                                                          <CardBody>
                                                            <textarea
                                                              className="form-control"
                                                              type="text"
                                                              name={"com" + i}
                                                              id={"comment" + i}
                                                              rows={1}
                                                              onChange={(e) => {
                                                                handleChecklistItemResult(
                                                                  "text",
                                                                  e,
                                                                  sec,
                                                                  item
                                                                );
                                                              }}
                                                              value={comments}
                                                            ></textarea>
                                                          </CardBody>
                                                        </Collapse>
                                                      </Card>
                                                    </div>

                                                    <div className="row mt-4 px-2">
                                                      <button
                                                        onClick={(event) => {
                                                          handleAddingPhoto(
                                                            event
                                                          );
                                                          AttachmentToggle();
                                                          setSelectedItem(item);
                                                          setSelectedSection(sec);
                                                        }}
                                                        className="btn btn-primary-blue text-center col"
                                                      >
                                                        Add Photos/Videos
                                                      </button>

                                                      <button
                                                        onClick={(event) => {
                                                          setSelectedItem(item);
                                                          setSelectedSection(sec);
                                                          linkRecordings(event);
                                                          recordingToggle(event);
                                                        }}
                                                        className="btn btn-primary-blue text-center col ms-2"
                                                      >
                                                        Link Recordings
                                                      </button>

                                                      <button
                                                        onClick={() => {
                                                          movePrev(
                                                            sec,
                                                            item,
                                                            sectionList.data,
                                                            checklistItemList.data,
                                                            ind,
                                                            i
                                                          );
                                                        }}
                                                        className="btn btn-primary-blue text-center col ms-0 ms-md-2 mt-2 mt-md-0"
                                                      >
                                                        Prev
                                                      </button>

                                                      <button
                                                        onClick={() => {
                                                          saveAnswerAndMoveNext(
                                                            sec,
                                                            item,
                                                            sectionList.data,
                                                            checklistItemList.data,
                                                            ind,
                                                            i
                                                          );
                                                        }}
                                                        className="btn btn-primary-blue text-center col ms-2 mt-2 mt-md-0"
                                                      >
                                                        Next
                                                      </button>
                                                    </div>
                                                  </CardBody>
                                                </Collapse>
                                              </Card>
                                            )
                                          )
                                      )}
                                    </div>
                                  </CardBody>
                                </Collapse>
                              </Card>
                            )
                          )}
                        </div>

                        {surveyDone &&location.state.data.surveyType == "Standard" ? (
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex justify-content-end mt-4">
                                <button
                                  onClick={() => handleProceed()}
                                  className="btn btn-primary-blue"
                                >
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : 
                        <div className="row">
                            <div className="col-12">
                              <div className="d-flex justify-content-end mt-4">
                                <button
                                  disabled={surveyDone==false?true:false}
                                  onClick={() => handleProceed()}
                                  className="btn btn-primary-blue"
                                >
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                        }


                        {/* {location.state.data.surveyType == "Sire" ?
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex justify-content-end mt-4">
                                <button
                                  onClick={() => handleProceed()}
                                  className="btn btn-primary-blue"
                                >
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                          : null} */}
                      </React.Fragment>
                    )}
                  </div>





                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal8} toggle={toggle8} size="lg">
        <ModalHeader className="bg-primary-blue" toggle={toggle8}>
          {"Attachment Preview"}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            {imgPreview.type === "image" ? (
              <img
                src={imgPreview.file}
                style={{ maxWidth: "300px", maxHeight: "500px" }}
                alt="preview"
              />
            ) : (
              <video
                controls
                src={imgPreview.file}
                style={{ maxWidth: "300px", maxHeight: "500px" }}
                alt="preview"
              />
            )}
          </div>

          <div className="row mt-4 justify-content-center">
            <div className="col-12 col-md-6">
              <input
                style={{ height: "fit-content" }}
                type="text"
                className={
                  filenameErr2.length > 0
                    ? "form-control is-invalid"
                    : "form-control"
                }
                onChange={(e) => {
                  var temp = e.target.value;
                  if (temp.length === 0) {
                    setFilenameErr2("Required !");
                  } else {
                    setFilenameErr2("");
                  }
                  setFilename2(e.target.value);
                }}
                value={filename2}
                placeholder="Enter filename"
              />
            </div>
            <div className="col-12 col-md-2">
              <button
                style={{ height: "fit-content" }}
                onClick={updateFilename}
                className="btn btn-primary-blue ms-0 ms-md-2"
              >
                Rename
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={attachmentModal}
        toggle={(event) => AttachmentToggle(event)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          className="bg-primary-blue"
          toggle={(event) => AttachmentToggle(event)}
        >
          {`Attachment for ${selectedItem?.section}`}
        </ModalHeader>
        <ModalBody>
          <AddPhotosVideos
            recordingVideos={recordingVideos}
            photosVideos={photosVideos}
            preview={preview}
            setPreview={setPreview}
            // handleClose={handleClose}
            AddData={AddData}
            toggle2={AttachmentToggle}
            handleAddingPhoto={handleAddingPhoto}
            topic={selectedItem}
            deletePhoto={deletePhoto}
            fetchSurveySectionItems={fetchSurveySectionItems}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={linkRecordingModal}
        toggle={(event) => recordingToggle(event)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          toggle={(event) => recordingToggle(event)}
          className="bg-primary-blue"
        >
          {"Link Video Call Recording"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {linkRecordingVideos.length > 0 ? (
              linkRecordingVideos.map((item, ri) => {
                return (
                  <>
                    {!!item?.url && (
                      <figure className="col-xl-3 col-sm-4" key={ri}>
                        <video
                          style={{
                            border:
                              selectedRecordedVideo == item
                                ? "2px solid #2246AB"
                                : "",
                            // padding: "10px",
                          }}
                          src={item?.url}
                          alt="Gallery"
                          className="img-thumbnail"
                          controls
                          onClick={() => {
                            if (selectedRecordedVideo == item) {
                              setSelectedRecordedVideo("");
                            } else {
                              setSelectedRecordedVideo(item);
                            }
                          }}
                        />
                      </figure>
                    )}
                  </>
                );
              })
            ) : (
              <p className="text-center">No Recordings Present !</p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={(event) => linkRecordingtoChecklist(event)}
          >
            Link
          </Button>
        </ModalFooter>
      </Modal>

      {
        linkModal ? (
          <GuestUserLinkGeneration
            toggleLinkModal={toggleLinkModal}
            linkModal={linkModal}
            data={id}
          />
        ) : null
      }

      <button
        onClick={() => {
          if (firebaseAuthFail) {
            toast.error("Cannot open chat, please contact admin!", {
              autoClose: 3000,
              theme: "light",

            });
          } else {
            setChatContainer(!chatContainer);
          }
        }}
        style={{
        // position: "relative",
          background: "#2264AB",
          color: "#FFF",
          position: "absolute",
          width: "50px",
          height: "50px",
          bottom: "2px",
          right: "10px",
          borderRadius: "50px",
          textAlign: "center",
          border: "none",
          zIndex: 9,
        }}
      >
        <MessageCircle
          className="cursor_pointer "
          height={"18px"}
          width={"18px"}
        />

        {unReadMsgCount > 0 ? (
          <span
            style={{
              background: "red",
              width: "auto",
              height: "auto",
              borderRadius: "12px",
              position: "absolute",
              top: "0px",
            }}
          >
            <p
              className="m-0"
              style={
                unReadMsgCount < 10
                  ? { padding: "2px 8px 2px 8px" }
                  : { padding: "2px 4px 2px 4px" }
              }
            >
              {unReadMsgCount}
            </p>
          </span>
        ) : null}
      </button>

      {chatContainer ? <ChatUI surveyId={id} /> : ""}
    </React.Fragment >
  );
}

export default React.memo(ConductSurvey);
