import React, { useState, useEffect } from "react";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import {
  convertDateYYMMDD,
  convertDate,
} from "../../Common/Functions/CommonFunctions";
import {
  getVessels,
  getSOP,
  getCustomersUsers,
  getCustomersUsersByRole,
  createSurvey,
  getChecklistSurveyApi,
} from "../../../../api/index";
import SweetAlert from "sweetalert2";
import { Search } from "react-feather";

const schema = yup
  .object()
  .shape({
    sop: yup
      .array()
      .min(1, "SOP is required !")
      .nullable()
      .required("SOP is required !"),
    surveyNo: yup.string().required("Survey number is required !"),
    checkList: yup
      .array()
      .min(1, "Checklist is required !")
      .nullable()
      .required("Checklist is required !"),
    mode: yup
      .string()
      .nullable()
      .required("Mode is required !")
      .matches(/^((?!Select).)*$/, "Please select valid option"),
    vessel: yup
      .array()
      .min(1, "Vessel is required !")
      .required("Vessel is required !"),
    user: yup
      .array()
      .min(1, "Surveyor is required !")
      .required("Surveyor is required !"),
    // verifier: yup.array().min(1, 'Verifier is required !').required('Verifier is required !'),
    // remoteSurveyor: yup.array().min(1, 'Remote Surveyor is required !').required('Remote Surveyor is required !'),
    startDate: yup.string().required("Start date is required !"),
    endDate: yup.string().required("End date is required !"),
    // status: yup
    //   .string()
    //   .nullable()
    //   .required("Status is required !")
    //   .matches(/^((?!Select).)*$/, "Please select valid option"),
  })
  .required();

function CreateNewSurveyForm(props) {
  const [modal8, setModal8] = useState();
  const [visible, setVisible] = useState(false);

  const [startDate, setstartDate] = useState(new Date());
  const [vesselList, setVesselList] = useState({ data: [], loading: false });
  const [sopList, setSOPList] = useState({ data: [], loading: false });
  const [userList, setUserList] = useState({ data: [], loading: false });
  const [surveyorList, setSurveyorList] = useState({
    data: [],
    loading: false,
  });
  const [siteSurveyorList, setSiteSurveyorList] = useState({
    data: [],
    loading: false,
  });
  const [verifierList, setVerifierList] = useState({
    data: [],
    loading: false,
  });
  const [checkList, setCheckList] = useState({ data: [], loading: false });
  const [csearch, setCsearch] = useState("");
  const [minEndDate, setMinEndDate] = useState({ date: "" });

  const customer_id = localStorage.getItem("customer_id");
  const subscriptionPlan = JSON.parse(localStorage.getItem("subscription"));

  const {
    register,
    handleSubmit,
    control,
    formState,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(Date.now() + 24 * 60 * 60 * 1000),
    },
  }); // initialise the hook

  let { errors } = formState;

  const sd = watch("startDate");
  useEffect(() => {
    console.log("sd", sd);
    minEndDate.date = sd;
    setMinEndDate({ ...minEndDate, date: minEndDate.date });
    if (sd !== undefined) {
      // console.log(new Date(new Date(sd).getTime() + (24 * 60 * 60 * 1000)));
      setValue(
        "endDate",
        new Date(new Date(sd).getTime() + 24 * 60 * 60 * 1000)
      );
      
    }
  }, [sd]);

  function searchChecklist() {
    fetchChecklist();
  }

  function fetchVessels() {
    setVesselList({ ...vesselList, data: [], loading: true });

    getVessels({
      customer_id: customer_id,
      pageNo: 1,
      entriesPerPage: 1000,
    }).then(
      (res) => {
        console.log("res", res);
        const tempList = res.data.res.filter((val) => {
          return (val.status===true);
        });
        setVesselList({ ...vesselList, data: tempList, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchChecklist() {
    setCheckList({ ...checkList, data: [], loading: true });

    // getChecklistSurveyApi({ customer: customer_id, noSurvey: "True" }).then((res) => {
    getChecklistSurveyApi({
      customer_id: customer_id,
      page: 1,
      searchVal: csearch,
      entriesPerPage: 10000,
    }).then(
      (res) => {
        console.log("res", res);

        // var result = [];
        // for (let i = 0; i < res.data.length; i++) {
        //     result.push(res.data[i].checklist);
        // }

        if (res.data.length !== 0) {
          console.log("1");
          setCheckList({ ...checkList, data: res.data.data, loading: false });
        } else {
          console.log("2");
          setCheckList({ ...checkList, data: [], loading: false });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }
  function fetchSOP() {
    setSOPList({ ...sopList, data: [], loading: true });

    getSOP({ customer_id: customer_id, pageNo: 1, entriesPerPage: 1000 }).then(
      (res) => {
        console.log("res", res);

        var result = [];
        for (let i = 0; i < res.data.res.length; i++) {
          result.push(res.data.res[i].SOP);
        }

        console.log("result", result);

        setSOPList({ ...sopList, data: result, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchUser(params) {
    setUserList({ ...userList, data: [], loading: true });

    getCustomersUsers(params).then(
      (res) => {
        console.log("res", res);

        setUserList({ ...userList, data: res.data.res, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchVerifiers(params) {
    setVerifierList({ ...verifierList, data: [], loading: true });
    getCustomersUsersByRole(params).then(
      (res) => {
        console.log("res", res);

        setVerifierList({
          ...verifierList,
          data: res.data.res,
          loading: false,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchSurveyors(params) {
    setSurveyorList({ ...surveyorList, data: [], loading: true });
    getCustomersUsersByRole(params).then(
      (res) => {
        console.log("res", res);

        if (res.data.res.length > 0) {
          const tempList = res.data.res.filter((val) => {
            return (val.role.name === "Surveyor" && val.status==="Active");
          });
          setSurveyorList({ ...surveyorList, data: tempList, loading: false });
        } else {
          setSurveyorList({
            ...surveyorList,
            data: res.data.res,
            loading: false,
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }
  function fetchSiteSurveyors(params) {
    setSiteSurveyorList({ ...siteSurveyorList, data: [], loading: true });
    getCustomersUsersByRole(params).then(
      (res) => {
        console.log("res", res);

        setSiteSurveyorList({
          ...siteSurveyorList,
          data: res.data.res,
          loading: false,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const toggle8 = () => {
   // setModal8(!modal8);
     setVisible(!visible)
    if (visible === false || visible === undefined) {
      // resetting the form
      reset();

      fetchVessels();
      fetchSOP();
      //   fetchChecklist();
      fetchUser({ customer_id: customer_id });
      fetchSurveyors({ customer_id: customer_id, searchVal: "Surveyor" });
      fetchVerifiers({ customer_id: customer_id, searchVal: "Verifier" });
      fetchSiteSurveyors({
        customer_id: customer_id,
        searchVal: "SiteSurveyor",
      });
    }
  };

  const onSubmit = (data) => {
    if (data !== "") {
      console.log("data", data);

      const reqPayload = {
        surveyNo: data.surveyNo,
        SOP_id: data.sop[0].Id,
        checklist_id: data.checkList[0].Id,
        mode: data.mode,
        vessel_id: data.vessel[0].Id,
        user_id: data.user[0].Id,
        startDate:
          convertDateYYMMDD(data.startDate, "-") +
          " " +
          new Date(data.startDate).toLocaleTimeString("it-IT"),
        endDate:
          convertDateYYMMDD(data.endDate, "-") +
          " " +
          new Date(data.endDate).toLocaleTimeString("it-IT"),
        status: "Active",
        customer_id: customer_id,
      };
      if (data.verifier !== undefined) {
        reqPayload.verifier_id = data.verifier[0]?.Id;
      }
      // if (data.remoteSurveyor !== undefined) {

      //     reqPayload.remoteSurveyor_id = data.remoteSurveyor[0]?.Id;
      // }
      if (data.siteSurveyor !== undefined) {
        reqPayload.remoteSurveyor_id = data.siteSurveyor[0]?.Id;
      }

      console.log("reqPayload", reqPayload);

      if (
        new Date(data.endDate).getTime() < new Date(data.startDate).getTime()
      ) {
        console.log("sd is less");

        SweetAlert.fire({
          title: "Please select a valid end date !",
          text: "Click ok to proceed!",
          icon: "error",
        });
      } else {
        const currDate = new Date(new Date().setHours(0, 0, 0, 0));
        const planStartDate = new Date(
          new Date(new Date(subscriptionPlan.startDate)).setHours(0, 0, 0, 0)
        );
        const planEndDate = new Date(
          new Date(new Date(subscriptionPlan.endDate)).setHours(0, 0, 0, 0)
        );

        if (
          currDate >= planStartDate === true &&
          currDate <= planEndDate === true
        ) {
          console.log("valid");
          console.log("props.totalRecords", props.totalRecords);
          console.log(
            "subscriptionPlan.plan.userCount",
            subscriptionPlan.plan.inspectionsCount
          );

          if (props.totalRecords < subscriptionPlan.plan.inspectionsCount) {
            console.log("valid");
            createSurvey(reqPayload).then(
              (res) => {
                console.log("res", res);
                toast.success("New survey assigned successfully !", {
                  autoClose: 3000,
                });
               // toggle8();
                 setVisible(!visible)
                props.fetchSurveys("refresh");
              },
              (err) => {
                console.log("err", err.response);

                if (err.response.status === 500) {
                  if (err.response.data.includes("already exists")) {
                    toast.error("Survey already exists for these details !", {
                      autoClose: 3000,
                      theme: "light",
                    });
                  } else {
                    toast.error("Something went wrong, please try again !", {
                      autoClose: 3000,
                      theme: "light",
                    });
                  }
                } else if (err.response.status === 403) {
                  toast.error("Invalid Subscription, contact admin !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                } else if (err.response.status === 400) {
                  toast.error("Survey number not available !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                } else {
                  toast.error("Something went wrong, please try again !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                }
              }
            );
          } else {
            toast.error("Invalid Subscription, contact admin !", {
              autoClose: 3000,
              theme: "light",
            });
          }
        } else {
          console.log("invalid");
          toast.error("Invalid Subscription, contact admin !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <button
       onClick={()=>{
        toggle8();
        setVisible(true);
      }}
        style={{ fontSize: "16px ", maxHeight: "40px" }}
        className="btn btn-primary-blue float-right"
      >
        Assign New Survey
      </button>

      <Modal show={visible} onHide={() => setVisible(false)} size="lg" backdrop="static">
        <Modal.Header className="bg-primary-blue" closeButton >
          {/* {'Create New Survey'} */}
          <Modal.Title > <p className="f-22 f-w-400 mb-0">{"Create New Survey"}</p></Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Survey Number"}</label>

                  <input
                    className={
                      errors.surveyNo
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type={"text"}
                    name="surveyNo"
                    placeholder="Survey Number"
                    {...register("surveyNo")}
                  />
                  <p className="mb-0 text-muted f-12">{`Survey No of previous survey : ${props.surveyNoOfLastRecord}`}</p>
                  <span className="text-danger">
                    {errors.surveyNo?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">
                    {"Instructions & Guidelines"}
                  </label>

                  <Controller
                    name="sop"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        emptyLabel={
                          sopList.data.length > 0
                            ? "No matches found."
                            : "No sop to select"
                        }
                        options={sopList.data}
                        loading={sopList.loading}
                        placeholder="--Select SOP*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.sop ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.sop?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Defined Survey"}</label>

                  <div className="d-flex">
                    <Controller
                      name="checkList"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="w-100"
                          id="basic-typeahead"
                          labelKey="name"
                          multiple={false}
                          options={checkList.data}
                          isLoading={checkList.loading}
                          emptyLabel={
                            checkList.data.length > 0
                              ? "No matches found."
                              : "No checklist to select"
                          }
                          placeholder="--Select checklist*--"
                          onChange={onChange}
                          onBlur={onBlur}
                          onInputChange={(input) => {
                            // console.log("Typed input:", input);
                            setCsearch(input);
                          }}
                          // selected={value}
                          isInvalid={errors.checkList ? true : false}
                        />
                      )}
                    />
                    <span
                      className="shadow-sm"
                      onClick={() => searchChecklist()}
                      style={{
                        background: "white",
                        border: "1px solid #c6c6c6",
                        position: "relative",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Search
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                        }}
                        id={"search"}
                        className="cursor_pointer"
                        height={"18px"}
                        width={"18px"}
                      />
                    </span>
                  </div>

                  <span className="text-danger">
                    {errors.checkList?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Mode"}</label>

                  <Controller
                    name="mode"
                    control={control}
                    defaultValue="Both"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={"Both"}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.mode
                            ? "custom-select form-control is-invalid f-16"
                            : " f-16 custom-select form-control"
                        }
                      >
                        <option select="">{"Select*"}</option>
                        <option value="Remote">{"Remote"}</option>
                        <option value="Online">{"Online"}</option>
                        <option value="Both">{"Both"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.mode?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Vessel"}</label>
                  <Controller
                    name="vessel"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={vesselList.data}
                        isLoading={vesselList.loading}
                        placeholder="--Select vessel*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.vessel ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.vessel?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  {/* <label className='f-w-600 f-14'>{"User"}</label> */}
                  <label className="f-w-600 f-14">{"Surveyor"}</label>

                  <Controller
                    name="user"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        // options={userList.data}
                        // isLoading={userList.loading}
                        // emptyLabel={userList.data.length > 0 ? 'No matches found.' : 'No user to select'}
                        options={surveyorList.data}
                        isLoading={surveyorList.loading}
                        emptyLabel={
                          surveyorList.data.length > 0
                            ? "No matches found."
                            : "No surveyor to select"
                        }
                        placeholder="--Select surveyor*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.user ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.user?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Verifier"}</label>
                  <div>
                    <Controller
                      name="verifier"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead"
                          labelKey="name"
                          multiple={false}
                          options={verifierList.data}
                          isLoading={verifierList.loading}
                          emptyLabel={
                            verifierList.data.length > 0
                              ? "No matches found."
                              : "No verifier to select"
                          }
                          placeholder="--Select verifier--"
                          onChange={onChange}
                          onBlur={onBlur}
                          // selected={value}
                          isInvalid={errors.verifier ? true : false}
                        />
                      )}
                    />
                  </div>
                  <span className="text-danger">
                    {errors.verifier?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Site Co-ordinator"}</label>
                  <Controller
                    name="siteSurveyor"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={siteSurveyorList.data}
                        isLoading={siteSurveyorList.loading}
                        emptyLabel={
                          siteSurveyorList.data.length > 0
                            ? "No matches found."
                            : "No site surveyor to select"
                        }
                        placeholder="--Select site coordinator--"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        // isInvalid={errors.verifier ? true : false}
                      />
                    )}
                  />

                  {/* <span className='text-danger'>{errors.verifier?.message}</span> */}
                </div>
              </div>

              {/* <div className='col-12 col-md-6'>
                                <div className="form-group mb-2">
                                    <label className='f-w-600 f-14'>{"Remote Surveyor"}</label>

                                    <Controller
                                        name="remoteSurveyor"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (

                                            <Typeahead
                                                id="basic-typeahead"
                                                labelKey="name"
                                                multiple={false}
                                                options={surveyorList.data}
                                                isLoading={surveyorList.loading}
                                                emptyLabel={surveyorList.data.length > 0 ? 'No matches found.' : 'No surveyor to select'}
                                                placeholder="--Select--"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                // selected={value}                                                
                                                isInvalid={errors.remoteSurveyor ? true : false}
                                            />

                                        )}
                                    />


                                    <span className='text-danger'>{errors.remoteSurveyor?.message}</span>
                                </div>
                            </div> */}

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">Start Date</label>

                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        className={
                          errors.startDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                          )
                        }
                        // maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.startDate?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">End Date</label>

                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        className={
                          errors.endDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={new Date(minEndDate.date)}
                        // maxDate={new Date()}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  <span className="text-danger">{errors.endDate?.message}</span>
                </div>
              </div>

              {/* <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Status"}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue="Active"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue="Active"
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.status
                            ? "form-control is-invalid f-16"
                            : " f-16 form-control"
                        }
                      >
                        <option select="">{"--Select*--"}</option>
                        <option value="Active">{"Active"}</option>
                        <option value="Inactive">{"Inactive"}</option>
                      </select>
                    )}
                  />
                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div> */}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            style={{ fontSize: "16px " }}
            className="btn btn-primary-blue float-right"
          >
            {"Create Survey"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default CreateNewSurveyForm;
