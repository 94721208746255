import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './i18n';
import { auth0 } from './data/config';
import { configureFakeBackend } from "./services/fack.backend";
import { BrowserRouter, Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { useLocation } from 'react-router-dom'

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import { Auth0Provider } from '@auth0/auth0-react'
import MainRoutes from './routes';
import { createBrowserHistory as createHistory } from 'history';
// import app  from './utils/firebase';
// import OneSignal from 'react-onesignal';
// setup fake backend
configureFakeBackend();


const Root = () => {
    // const prevLocation = useLocation().pathname;

    const history = createHistory();

    

    useEffect(() => {
        // console.log("firebase", firebase);
        const unlisten = history.listen((location) => {
            console.log("location", location);
            console.log("window.location.pathname", window.location.pathname);


            const urlToBlock = localStorage.getItem('urlToBlock');

            if (urlToBlock !== null) {

                if (location.pathname !== urlToBlock) {
                    // history.push(prevLocation)
                    console.log("location.pathname", location.pathname);
                    // const temp = prompt('Are you sure want to exit this page');
                    // console.log("temp", temp);
                    // if (temp === null) {
                    //     console.log('urlToBlock', urlToBlock);
                    // } else {
                    //     localStorage.removeItem('urlToBlock');
                    // }

                    // if (myUrl === `${process.env.PUBLIC_URL}/customeradmin/vdi_survey_details`) {
                    //Do something, like display confirmation dialog!
                    // }
                }
            }
        });


        return () => {
            unlisten()
        }
    }, [])

    function generatePassword() {
        var length = 16,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }




    return (
        <div className="App">
            {/* <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}> */}
            <Provider store={store}>
                <BrowserRouter history={history} getUserConfirmation={() => {/* Empty callback to block the default browser prompt */ }}>

                    <MainRoutes />
                </BrowserRouter>
            </Provider>
            {/* </Auth0Provider> */}
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();