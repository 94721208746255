import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { uploadChecklistItemAttachment, uploadChecklistItemAttachmentv3 } from "../../../../api/index";
import { toast } from "react-toastify";
import { compressFile } from "../../Common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    file: yup.string().required("Required !"),
  })
  .required();

var FormData = require("form-data");

function UploadFile(props) {
  const { register, formState, control, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const [data, setData] = useState({});
  const [compress2, setCompress2] = useState(false);

  useEffect(() => {
    console.log("props", props);

    if (
      props.fileUploadModal === false ||
      props.fileUploadModal === undefined
    ) {
      reset();
    }
  }, [props]);

  async function handleChange(e) {
    console.log(e.target.files);

    let file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      console.log("Selected file is an image");
      setCompress2(true);
      await compressFile(e.target.files[0]).then(
        (res) => {
          console.log("res", res);
          setData(res);
          setCompress2(false);
        },
        (err) => {
          console.log("err", err);
          setCompress2(false);
          toast.error("Error in compressing image", {
            autoClose: 1000,
            theme: "light",
            
          });
        }
      );
    } else {
      setData(file);
    }
  }

  async function handleSubmit() {
    console.log("data", data);
    console.log("props", props.surveyId);
    console.log("props.dataForAttachmentUpload", props.dataForAttachmentUpload);

    var reqPayload;

    if (props.surveyId) {
      reqPayload = {
        checklist_file: data,
        checkListItem: props.dataForAttachmentUpload.Id,
        // survey: props.surveyId,
        withAnswer: "False",
        createdBy: parseInt(localStorage.getItem("vdi_User_Id")),
      };
    } else {
      reqPayload = {
        checklist_file: data,
        checkListItem: props.dataForAttachmentUpload.Id,
        withAnswer: "False",
        createdBy: parseInt(localStorage.getItem("vdi_User_Id")),
        // survey: props.surveyId
      };
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(await showPosition);
    }

    function showPosition(position) {
      console.log(
        "position.coords.latitude ",
        Number(Number(position.coords.latitude).toFixed(6))
      );
      console.log(
        "position.coords.longitude ",
        Number(Number(position.coords.longitude).toFixed(6))
      );

      reqPayload.lat = Number(Number(position.coords.latitude).toFixed(6));
      reqPayload.long = Number(Number(position.coords.longitude).toFixed(6));

      if (
        reqPayload.checklist_file.name.endsWith(".jpeg") ||
        reqPayload.checklist_file.name.endsWith(".JPEG")
      ) {
        reqPayload.fileType = "Image";
      } else if (
        reqPayload.checklist_file.name.endsWith(".png") ||
        reqPayload.checklist_file.name.endsWith(".PNG")
      ) {
        reqPayload.fileType = "Image";
      } else if (
        reqPayload.checklist_file.name.endsWith(".jpg") ||
        reqPayload.checklist_file.name.endsWith(".JPG")
      ) {
        reqPayload.fileType = "Image";
      } else if (reqPayload.checklist_file.name.endsWith(".mp4")) {
        reqPayload.fileType = "Video";
      } else if (reqPayload.checklist_file.name.endsWith(".WMV")) {
        reqPayload.fileType = "Video";
      } else if (reqPayload.checklist_file.name.endsWith(".MOV")) {
        reqPayload.fileType = "Video";
      } else if (reqPayload.checklist_file.name.endsWith(".pdf")) {
        reqPayload.fileType = "Document";
      } else if (
        reqPayload.checklist_file.name.endsWith(".xlsx") ||
        reqPayload.checklist_file.name.endsWith(".xls")
      ) {
        reqPayload.fileType = "Document";
      } else if (
        reqPayload.checklist_file.name.endsWith(".docx") ||
        reqPayload.checklist_file.name.endsWith(".doc")
      ) {
        reqPayload.fileType = "Document";
      }

      reqPayload.name = data.name;
      console.log("", reqPayload.checklist_file.name);
      console.log("reqPayload", reqPayload);

      var formdata = new FormData();

      for (var key in reqPayload) {
        formdata.append(key, reqPayload[key]);
      }

      uploadChecklistItemAttachmentv3(formdata).then(
        (res) => {
          console.log("res", res);


          if (res.status == 200 || res.status == 201) {
            toast.success("Attachment uploaded successfully !", {
              autoClose: 3000,
              theme: "light",
            });
            props.toggleFileUploadModal();
          props.fetchChecklistSections();
          }
          
        },
        (err) => {
          console.log("err", err.response);
          // let message="Something Went Wrong !";
          // if(err.response.data.error=="File Extention Not Allowed ." && err.response.status==400){
          //    message="File extension is not allowed !"
          // }
          // toast.error(`${message}`,{
          //   autoClose: 3000,
          //   theme: "light",
          // })

          if(err.response==undefined){
            toast.error("File size is too large !", {
              autoClose: 3000,
              theme: "light",
            });
           }else{
            let message="Something Went Wrong !";
            if(err.response.data.error=="File Extention Not Allowed ." && err.response.status==400){
               message="File extension is not allowed !"
            }
            toast.error(`${message}`,{
              autoClose: 3000,
              theme: "light",
            })
           }

          props.toggleFileUploadModal();
          props.fetchChecklistSections();
          console.log("err", err);
        }
      ).catch((err)=>{
        console.log("err", err.response);
      })
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.fileUploadModal}
        toggle={props.toggleFileUploadModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader
          toggle={props.toggleFileUploadModal}
          className="bg-primary-blue"
        >
          {"Upload File"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <div className="form-group">
                <label className="f-w-600">File</label>
                <input
                  className="form-control"
                  type={"file"}
                  accept=".jpg, .jpeg, .png, .wmv, .mp4, .mov, .pdf, .doc, .docx,.xls,.xlsx,"
                  name="file"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            disabled={compress2}
            className="btn btn-primary-blue"
            onClick={handleSubmit}
          >
            Upload
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default UploadFile;
