import React, { useEffect, useState, memo } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Nav, NavItem, NavLink, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap'
import { Collapse, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { get_mla, get_nested_mla, get_noc, postChecklistItemAnswer, uploadChecklistItemAttachmentv3, updateChecklistItemAnswer, delChecklistItemAttachment, renameChecklistItemAttachment, getChecklistItemAttachment, uploadChecklistItemAttachment } from "../../../../api";
import { convertUrl } from "../../../ProjectComponents/Common/Functions/CommonFunctions";
import { Accordion } from 'react-bootstrap';
import { Contact, Data, Homes, Profile } from "../../../../constant";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import MLA_answers from "./MLA_answers";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import { Eye, Trash2, XCircle } from "react-feather";
import { getData } from "../../../../api/APIinstance";
import Slider from "react-slick";
import { extractExtension } from "../../../ProjectComponents/Common/Functions/CommonFunctions";


const Sire_flow = (props) => {

    const { value, item, key } = props;
    const [isOpen, setIsOpen] = useState(1);
    const [isOpen2, setIsOpen2] = useState(1);

    // const toggle2 = (id) => (isOpen2 === id ? setIsOpen2(null) : setIsOpen2(id));
    const [BasicTab, setBasicTab] = useState(1);
    const [jsx, setJsx] = useState({ data: "", loading: false });
    const [data, setData] = useState([]);

    const [content, setContent] = useState({});
    const [soc_selected, setSoc_selected] = useState({ Id: "", select: false, data: {} });
    const [soclist, setSocList] = useState({ data: [], loading: false });
    const [noclist, setNocList] = useState({ data: [], loading: false });
    const [photos, setPhotos] = useState(null);
    const [modal8, setModal8] = useState();
    const [modal9, setModal9] = useState();
    const [filename, setFilename] = useState({ data: {}, updated_name: "" });
    const localData = JSON.parse(localStorage.getItem("login_data"));
    const { id, cid } = useParams();
    const [photo_input, setPhoto_input] = useState(Date.now())
    const [selected_mla, setSelected_mla] = useState({});
    const [selecteditem_id, setSelecteditem_id] = useState()
    // const [socMaster, setSocMaster] = useState({ data: [], loading: false });
    const [photosVideos, setPhotosVideos] = useState({
        data: [],
        loading: false,
    });
    const [recordingVideos, setRecordingVideos] = useState([]);
    const [preview, setPreview] = useState([]);
    const [filenameErr, setFilenameErr] = useState("");
    const [loader, setLoader] = useState(true);
    let socMaster = [];
    let a = [];

    const [comment, setComment] = useState();
    const [socans, setSocans] = useState();
    const [nocans, setNocans] = useState();
    const toggle = (id, item) => {
        console.log("id", id);
        console.log("value", value);
        console.log("item", item)

        if (item) {
            console.log("selecteditem_id", selecteditem_id)
            setSelecteditem_id(item.Id);
        }

        if (isOpen === id) {
            setIsOpen(null)
        }
        else {
            setIsOpen(id)
        }
    };


    // useEffect(()=>{
    //     window.history.pushState(null, "", window.location.href);
    //     window.onpopstate = function () {
    //       window.history.pushState(null, "", window.location.href);
    //     };
    // },[])



    // handle adding photo 

    const handleAddingPhoto = async (event) => {
        setPhotosVideos({ ...photosVideos, loading: true, data: [] });

        getChecklistItemAttachment({
            survey: id,
            withSurveyOnly: "False",
            withTable: "False",
        }).then(
            (res) => {
                console.log("res", res);
                setPhotosVideos({ ...photosVideos, loading: false, data: res.data });
            },
            (err) => {
                console.log("err", err);
            }
        );
        // setPhotosVideos(response);
    };


    //handle recording video

    const fetchVideoRecording = async () => {
        setRecordingVideos({ ...recordingVideos, loading: true, data: [] });
        let resFromRecording = await getData(`SurveyRecordings/?survey=${id}`);

        setRecordingVideos({
            ...recordingVideos,
            loading: false,
            data: resFromRecording,
        });
    };

    //handle previewadd 

    const previwAdd = (data) => {
        console.log("data=========>", data);

        setFilename({ data: data, updated_name: data.name })
        setPhotos(data)
        if (preview.includes(data)) {
            let a = preview.filter((el) => el.Id != data.Id);
            console.log("a", a);

            setPreview(a);
            // setLoader(false);
        } else if (!preview.includes(data) && preview.length != 3) {
            setPreview((prev) => [...prev, data]);
            setLoader(false);
            console.log("data", data);
            // setFilename(data.name);
        }
    };

    //basic settings

    var basic_settings2 = {
        dots: false,
        infinite: false,
        speed: 100,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        adaptiveHeight: true,
        centerPadding: "10px",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    background: "#000000",
                    borderRadius: 50,
                }}
                onClick={onClick}
            />
        );
    }
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    background: "#000000",
                    borderRadius: 50,
                }}
                onClick={onClick}
            />
        );
    }

    useEffect(() => {
        console.log("props", props)
        console.log("item", item)
        if (item.sequence == 1) {
            setSelecteditem_id(item.Id);
        }
    }, [])
    //Fetchinig noc list when perticular soc is selected form noc category id
    const fetch_noc_list = (val) => {
        setNocList({ data: [], loading: true })
        const params = {
            nocCategoryId: val,
            customer: localData.customer_id
        }
        get_noc(params).then((res) => {
            setNocList({ data: res.data.payload, loading: false })
        })
            .catch((er) => console.log(er));
    }

    //function for nested soc structure 
    const findParents = (items, targetSoc, parents = []) => {
        for (const item of items) {
            if (item.Id === targetSoc) {
                const { SocMasterChild, ...parentWithoutChildren } = item;
                parents.push(parentWithoutChildren);
                return parents;
            }

            if (item.SocMasterChild) {
                const children = item.SocMasterChild;
                const updatedParents = [...parents, item];

                const result = findParents(children, targetSoc, updatedParents);
                if (result != null) {
                    return result;
                }
            }
        }

        return null;
    };






    //Function for selecting the soc 
    const handleselect = (val) => {
        console.log("selected soc", val);
        // let obj = findParents(soclist.data, val.Id);


        // const modifiedData = obj.map(obj => {
        //     const { SocMasterChild, ...rest } = obj;
        //     return rest;
        // });

        // console.log(modifiedData);


        // content.socAns=val;
        setSocans(val)


        // content.socAnsList = modifiedData;

        fetch_noc_list(val.nocCategoryId);
        setSoc_selected({ Id: val.Id, select: !soc_selected.select, data: val })
        console.log("content", content);
        // setContent({ ...content, nocAns: [] });
        // content.nocAns = {};
        // setContent(content)
    }
    // This is for showing the gallary modal
    const toggle8 = () => {
        setModal8(!modal8)
    }

    const toggle9 = () => {
        setModal9(!modal9)
    }

    // For redering the data in tree structur
    function renderData(data) {
        console.log("data", data);
        return (
            data.map((item, i) => (
                <>
                    <li onClick={(e) => displayContent(item, e)} key={new Date().getTime() + item.description}>
                        <span className="caret">{item.description}</span>
                        <hr className="my-2 w-100" />
                        {
                            item.child.length > 0 ? (
                                <>
                                    <ul className="nested ms-4">
                                        {renderData(item.child)}
                                    </ul>
                                </>
                            ) : (
                                ""
                            )
                        }
                    </li>
                </>

            ))

        );
    }



    function displayContent(item, e) {
        console.log("item", item);
        console.log("e", e);
        if (item.socAns) {
            setSoc_selected({ Id: item.socAns.Id, select: true, data: item })
        }
        filterSOC(item)
        e.stopPropagation();
        setContent({ ...item })
    }

    // After clicking on zero level MLA we are filtering through data
    function fetchLevOne(mla) {

        console.log(mla, "ajklsd;f")
        setJsx({ ...jsx, data: "", loading: true })

        // fetching seletect mla here
        get_nested_mla({ mlaIds: `[${mla.Id}]`, customer: localData.customer_id }).then((res) => {
            console.log("res", res)
            const result = res.data.MLA
            console.log("result", result);

            let showData = result[0];

            console.log("showData", showData)
            // Taking latest answer from outcome api, checking if outcome has answer of selected mla
            //  then storing it in local storage
            console.log(props.item.checklist_item_result[props.item.checklist_item_result.length - 1]);



            if (props.item.checklist_item_result.length !== 0) {
                let a = props.item.checklist_item_result[props.item.checklist_item_result.length - 1].sireAnswer;
                console.log('getting data', a)
                //console.log(mla,"mla")


                let arr = a.filter((el) => (el.Id == mla.Id))
                console.log('filterdata', arr)
                if (arr.length > 0) {
                    console.log("arr[0]", arr[0]);
                    showData = arr[0]

                }
                localStorage.setItem(`SireAnsHierarchy`, JSON.stringify(showData));
            }


            localStorage.setItem(`SireAnsHierarchy`, JSON.stringify(showData));


            // Here we are generating the UI of nested MLA tree
            const renderedHTML = renderData(showData.child);
            setData([...showData.child])
            console.log("renderedHTML", renderedHTML);
            setJsx({ ...jsx, data: renderedHTML, loading: false })

            // setSocMaster({ data: , loading: false });
            socMaster = res.data.socMaster;


            // This below code is used to handle events of mla tree functionality on UI
            var toggler = document.getElementsByClassName("caret");
            // console.log(toggler)
            var i;

            for (i = 0; i < toggler.length; i++) {
                toggler[i].addEventListener("click", function () {
                    this.parentElement.querySelector(".nested").classList.toggle("active");
                    this.classList.toggle("caret-down");

                });
            }
        }, (err) => {
            console.log("err", err)
        })
    }

    // Filtering soc from soc_Masters
    function filterSOC(umla) {
        console.log("umla", umla)
        console.log("socMaster", socMaster)

        if (umla.soc !== null) {
            const res = socMaster.filter((val) => {
                return val.socCategoryId == umla.socCategoryId
            })
            console.log("res", res)
            setSocList({ ...soclist, data: res, loading: false });
        }
    }
    //For validating the perticular MLA inside data  
    const validateObject = (obj) => {
        const { comments, commentAns, photo, photoAns, soc, socAns, nocAns } = obj;

        // If comments is mandatory, validate commentAns has value
        if (comments === "Mandatory" && !commentAns) {
            return false;
        }

        // If photo is mandatory, photo should have list of images
        if (photo === "Mandatory" && !photoAns?.length) {
            return false;
        }

        // If soc is true, socAns should have value
        if (soc && !socAns) {
            return false;
        }

        //If soc is tru , nocAns should have value 
        if (soc && !nocAns && Object.keys(nocAns).length == 0) {
            return false;
        }

        // If all these are validated function should return true else false
        return true;
    };

    function identifyStringOrObject(temp) {
        for (const item of temp) {
            if (typeof item === "object") {
                console.log("item", item);
            }
        }
    }


    // Function for SOC and NOC 
    function eachRecursive(obj, a, b) {
        for (var k in obj) {
            if (typeof obj[k] == "object" && obj[k] !== null) {
                eachRecursive(obj[k], a, b);
            }

            else {

                if (obj.socAns && obj.socAns.length > 0) {
                    for (let i = 0; i < obj.socAns.length; i++) {
                        if (!a.includes(obj.socAns[i].Id)) {
                            a.push(obj.socAns[i].Id);
                        }
                    }
                }



                if (obj.nocAns && obj.nocAns.length > 0) {
                    for (let i = 0; i < obj.nocAns.length; i++) {

                        //   console.log("noc Id",obj.nocAns[i])
                        if (!b.includes(obj.nocAns[i].Id)) {

                            b.push(obj.nocAns[i].Id);
                        }
                    }
                }


            }

        }
    }
    // for updating the json when ever we are changing data so updating in json
    function replaceObjectById(para1, replacementObj) {
        for (let i = 0; i < para1.length; i++) {
            const item = para1[i];
            if (item.Id === replacementObj.Id) {
                console.log(`${item.Id} ------- ${replacementObj.Id}`);
                // Replace the object with the provided replacement object
                para1[i] = replacementObj;
                return true;
            } else if (item.child.length > 0) {
                if (replaceObjectById(item.child, replacementObj)) {
                    return true;
                }
            }
        }
        return false;
    }
    // After clicking the save button we are saving the data to the local storage 
    async function SaveAnswer(arr, update, obj) {
        console.log("data", data);
        console.log("content", content);

        console.log("comeent", comment)
        console.log("socans", socans);
        console.log("nocans", nocans);

        console.log("arr", arr)
        if (arr == undefined && update !== "update") {
            if (content.soc && comment == undefined && socans !== null & nocans !== null) {
                toast.error("Please fill the comment !", {
                    autoClose: 2000
                })
                return
            }
            if (content.soc && socans == undefined && comment !== "" && nocans !== null) {
                toast.error("Please select SOC !", {
                    autoClose: 2000
                })
                return
            }

            if (content.soc && nocans == undefined && comment !== "" && socans !== null) {
                toast.error("Please select NOC !", {
                    autoClose: 2000
                })
                return
            }
        }






        if (comment && arr == undefined && update !== "update") {
            if (content.commentAns == undefined) {
                content.commentAns = [comment];
                setComment("")
            }
            else if (content.soc && content.commentAns.length > 0) {
                content.commentAns = [...content.commentAns, comment];
                setComment("")
            }
            else {
                content.commentAns = [comment];
                setComment("")
            }

        }

        if (socans && arr == undefined && update !== "update") {

            let obj = findParents(soclist.data, socans.Id);


            const modifiedData = obj.map(obj => {
                const { SocMasterChild, ...rest } = obj;
                return rest;
            });


            if (content.socAns == undefined) {
                content.socAns = [socans]
                let obj = {};
                obj.socList = modifiedData;
                content.socAnsList = [obj];

                setSocans();
            }
            else {
                content.socAns = [...content.socAns, socans];
                let obj = {};
                obj.socList = modifiedData;
                content.socAnsList = [...content.socAnsList, obj];

                setSocans();
            }

        }

        if (nocans && arr == undefined && update !== "update") {
            if (content.nocAns == undefined) {
                content.nocAns = [nocans];

            }
            else {
                content.nocAns = [...content.nocAns, nocans];
                setNocans();
            }

        }



        console.log("photos", photos);


        setPhoto_input(Date.now())
        if (arr) {
            content.photoAns = arr;

            setContent(content);
            saveInLocalStorage(null, "delete")
        }
        else if (update == "update") {
            let arr = content.photoAns.map((item) => {
                if (item.Id == obj.Id) {
                    item.name = obj.name;
                    return item
                }
                else {
                    return item
                }

            })
            // console.log("arr",arr)
            content.photoAns = arr;
            saveInLocalStorage(null, "update");

        }
        else {
            if (content.photo !== "NR") {
                console.log("photos", photos);
                if (photos !== null) {

                    if (photos.checklist_file == undefined) {

                        // console.log("item",item)
                        const payload = {
                            checkListItem: item.Id,
                            fileType: "Image",
                            checklist_file: photos,
                            withAnswer: "True"
                        }
                        var formdata = new FormData();

                        for (var key in payload) {
                            formdata.append(key, payload[key]);
                        }


                        console.log("formdata", formdata)
                        uploadChecklistItemAttachmentv3(formdata).then((res) => {
                            console.log("res", res)

                            if (content.photoAns && content.photoAns.length > 0) {
                                let photo = { ...res.data }
                                const photoUrl = convertUrl(res.data.checklist_file);
                                photo.checklist_file = photoUrl

                                content.photoAns.push(photo)
                                setContent(content);
                            } else {
                                content.photoAns = []
                                let photo = { ...res.data }
                                const photoUrl = convertUrl(res.data.checklist_file);
                                photo.checklist_file = photoUrl
                                content.photoAns.push(photo)
                                setContent(content);
                            }

                            saveInLocalStorage()

                        }, (err) => {
                            console.log("err", err)

                        })
                    }
                    else {
                        // console.log("item",item)
                        let item = { ...photos };
                        const fileForConversion = item.checklist_file.split("?");
                        let response = await fetch(fileForConversion[0], {
                            headers: {},
                        });
                        console.log("res", response.url)
                        let blob = await response.blob();
                       const etn = extractExtension(response.url);

                        blob = new File([blob], `${item.name}.${etn}`, {
                            type:
                                item.fileType == "Image"
                                    ? "image/jpeg"
                                    : item.fileType == "Video"
                                        ? "video/mp4"
                                        : "",
                        });
                        console.log("blod", blob)
                        console.log("filetype", item.fileType)
                        const payload = {
                            checklist_file: blob,
                            checkListItem: selecteditem_id,
                            fileType: item.fileType,
                            withAnswer: "True",
                            survey: id,
                            name: item.name,
                            lat: item.lat !== null ? item.lat : "",
                            long: item.long !== null ? item.long : "",
                            createdBy: item.createdBy.Id
                        }
                        console.log("payload,", payload);
                        const formDataToSend = new FormData();

                        for (const [key, value] of Object.entries(payload)) {
                            formDataToSend.append(key, value);
                        }

                        uploadChecklistItemAttachmentv3(formDataToSend).then((res) => {
                            console.log("res", res)

                            if (content.photoAns && content.photoAns.length > 0) {
                                let photo = { ...res.data }
                                const photoUrl = convertUrl(res.data.checklist_file);
                                photo.checklist_file = photoUrl

                                content.photoAns.push(photo)
                                setContent(content);
                            } else {
                                content.photoAns = []
                                let photo = { ...res.data }
                                const photoUrl = convertUrl(res.data.checklist_file);
                                photo.checklist_file = photoUrl
                                content.photoAns.push(photo)
                                setContent(content);
                            }

                            saveInLocalStorage()
                            setPhotos(null)

                        }, (err) => {
                            console.log("err", err)

                        });







                    }

                } else {
                    saveInLocalStorage()
                }
            } else {
                saveInLocalStorage()
            }
        }


        //   let clicked_value=JSON.parse(localStorage.getItem("MLA"));
        //   let mla_data=JSON.parse(localStorage.getItem("MLA_DATA"));
        //      clicked_value=Number(clicked_value);
        //      if(clicked_value){
        //         console.log("clicked_on",clicked_value)
        //        let mla_clicked= document.getElementById(`MLA${mla_data.Id}`);
        //            console.log("mla_clicked",mla_clicked.classList)
        //        let nav_ele=document.getElementById(`MLA${mla_data.Id}${0}`);
        //        nav_ele.classList.remove('active')
        //  mla_clicked.addEventListener("click",function(){
        //     console.log("clicked_on",clicked_value)
        //     console.log("Basitab",BasicTab)
        //     setBasicTab(clicked_value); fetchLevOne(mla_data); setContent({})
        //  })
        //  mla_clicked.click();
        //  setBasicTab(clicked_value); fetchLevOne(mla_data); setContent({})




        //fetchLevOne(mla_data)
        // if (mla_clicked) {
        //     console.log("asldf",mla_clicked.classList)
        //     mla_clicked.classList.add('active');
        //   }



        // }

    }
    //Save to the local storage
    function saveInLocalStorage(obj, comment) {

        if (obj) {

            console.log("data", data);
            console.log("content", obj);
            if (replaceObjectById(data, obj)) {
                const SireAnsHierarchy = JSON.parse(localStorage.getItem("SireAnsHierarchy"));
                SireAnsHierarchy.child = data
                localStorage.setItem("SireAnsHierarchy", JSON.stringify(SireAnsHierarchy))
                // toast.success("Data saved successfully !", {
                //     autoClose: 1000,
                //     theme: "light",
                // });
                getLocalStoDataAndFire();

                // .then(()=>  fetchLevOne(selected_mla));

            }


        }
        else if (comment == "delete" || comment == "update") {

            console.log("in else if");
            console.log("data", data);
            console.log("content", content);
            if (replaceObjectById(data, content)) {
                const SireAnsHierarchy = JSON.parse(localStorage.getItem("SireAnsHierarchy"));
                SireAnsHierarchy.child = data
                localStorage.setItem("SireAnsHierarchy", JSON.stringify(SireAnsHierarchy))

                getLocalStoDataAndFire();



            }
        }
        else {

            if (validateObject(content)) {
                console.log("replaceObjectById(data, content)", replaceObjectById(data, content));
                console.log("data", data);
                console.log("content", content);
                if (replaceObjectById(data, content)) {
                    const SireAnsHierarchy = JSON.parse(localStorage.getItem("SireAnsHierarchy"));
                    SireAnsHierarchy.child = data
                    localStorage.setItem("SireAnsHierarchy", JSON.stringify(SireAnsHierarchy))
                    // toast.success("Data saved successfully !", {
                    //     autoClose: 1000,
                    //     theme: "light",
                    // });
                    getLocalStoDataAndFire();

                    // .then(()=>  fetchLevOne(selected_mla));

                }
            }
            else {
                toast.error("Please fill required data !");
            }
        }

    }

    // delete soc noc & comment 
    function delete_soc_noc_commet(ind) {
        console.log("content", content);

        let sarr = [];
        let narr = [];
        let carr = [];
        let solist = [];

        for (let i = 0; i < content.commentAns.length; i++) {

            if (i !== ind) {
                carr.push(content.commentAns[i]);
                sarr.push(content.socAns[i]);
                narr.push(content.nocAns[i]);
                solist.push(content.socAnsList[i]);
            }
        };

        console.log("arrays", carr, sarr, narr)
        setContent({ ...content, commentAns: carr, socAns: sarr, nocAns: narr, socAnsList: solist });
        let ob = { ...content, commentAns: carr, socAns: sarr, nocAns: narr, socAnsList: solist };
        saveInLocalStorage(ob);


    }
    let parentArr = [];
    let childArr = [];

    function one(child_arr) {
        try {
            for (let i = 0; i < child_arr.length; i++) {
                let newchild_arr;
                if (child_arr[i].commentAns != null ||
                    child_arr[i].photoAns != null ||
                    child_arr[i].socAns != null) {
                    newchild_arr = { ...child_arr[i] }
                    newchild_arr.child = [];
                    childArr.push(newchild_arr)
                    console.log("child_arr[i]", child_arr[i]);
                }
                else {
                    if ((child_arr[i].comments.toString().toLowerCase() == "optional" || child_arr[i].comments.toString().toLowerCase() == "nr") &&
                        (child_arr[i].photo.toString().toLowerCase() == "optional" || child_arr[i].photo.toString().toLowerCase() == "nr") &&
                        child_arr[i].soc == false) {
                        newchild_arr = { ...child_arr[i] }
                        newchild_arr.child = [];
                        childArr.push(newchild_arr)
                        // child_arr[i].child = []
                        console.log("child_arr[i]", child_arr[i]);
                    }
                    // else {
                    //     newchild_arr = { ...child_arr[i] }
                    //     newchild_arr.child = [];
                    //     childArr.push(newchild_arr)
                    //     console.log("child_arr[i]", child_arr[i]);
                    // }
                }


                if (child_arr[i].child.length > 0) {
                    // console.log("running");
                    one(child_arr[i].child)
                }
            }
        } catch (err) {
            console.log("err", err);
        }
    }


    //Get from local storage and save to the outcome api
    function getLocalStoDataAndFire(val) {
        let a = [];
        let b = [];
        const result = JSON.parse(localStorage.getItem("SireAnsHierarchy"));
        let survey_id = JSON.parse(localStorage.getItem("SurveyData"));
        let customerid = JSON.parse(localStorage.getItem("customerId"));

        let latestAnsFromOutcomeApi = result;
        if (props.item.checklist_item_result[props.item.checklist_item_result.length - 1]) {


            latestAnsFromOutcomeApi = props.item.checklist_item_result[props.item.checklist_item_result.length - 1].sireAnswer;
            console.log("latestAnsFromOutcomeApi", latestAnsFromOutcomeApi);
            console.log("result", result);

            const index = latestAnsFromOutcomeApi.findIndex(obj => obj.Id === result.Id);
            console.log("index", index)
            if (index == "-1") {
                latestAnsFromOutcomeApi[latestAnsFromOutcomeApi.length] = result;
            } else {
                latestAnsFromOutcomeApi[index] = result;
            }

            console.log("latestAnsFromOutcomeApi", latestAnsFromOutcomeApi);
        }
        console.log("latestAnsFromOutcomeApi", latestAnsFromOutcomeApi);

        if (latestAnsFromOutcomeApi[0] == undefined) {

            eachRecursive(latestAnsFromOutcomeApi, a, b)
        } else {
            for (let i = 0; i < latestAnsFromOutcomeApi.length; i++) {
                eachRecursive(latestAnsFromOutcomeApi[i], a, b);
            }
        }


        const payload = {
            checkListItem: item.Id,
            values: null,
            remarks: null,
            createdBy: customerid,
            survey: survey_id,
            tag:
            {
                noc: [...b],
                soc: [...a]

            }
        }



        if (props.item.checklist_item_result.length == 0) {
            // keep post api here
            payload.sireAnswer = JSON.parse(JSON.stringify([latestAnsFromOutcomeApi]))
            console.log(payload, "payload inside post ");


            const tempLatest = JSON.parse(JSON.stringify([...payload.sireAnswer]));

            parentArr = [];
            for (let i = 0; i < tempLatest.length; i++) {
                childArr = [];
                if (tempLatest[i].child.length > 0) {
                    one(tempLatest[i].child)
                    parentArr.push(childArr)
                }

            }

            for (let i = 0; i < tempLatest.length; i++) {
                tempLatest[i].child = parentArr[i]
            }

            payload.reportAnswer = {};
            payload.reportAnswer.answer = tempLatest;
            //const itemStatus = item.MLA.every(item1 => payload.reportAnswer.answer.some(item2 => item1.Id === item2.Id && item2.child.length > 0));
            // payload.reportAnswer.answerPass = itemStatus;
            if (payload.reportAnswer.answer[0].child.length > 0) {
                payload.reportAnswer.answerPass = true;
            }

            console.log("answerpass", payload.reportAnswer.answerPass)
            //  console.log("payload", payload)
            // console.log("parentArr", parentArr)
            //  console.log("tempLatest", tempLatest)
            console.log("payload.reportAnswer", payload.reportAnswer)
            postChecklistItemAnswer(payload).then((res) => {

                if (res) {
                    toast.success("Answer submited successfully !", {
                        autoClose: 1000,
                        theme: "light",
                    })
                    if (val !== "delete" || "edit") {
                        props.fetchSurveySectionItems({
                            customer_id: localData.customer_id,
                            survey_id: id,
                            user: localData.user,
                            section_id: props.sectionDetails.id,
                        });
                        props.fetchSurveyStatus();
                    }

                }
            }).catch((er) => {
                return toast.error(`${er}`)
            })
        } else {
            // keep patch api here
            console.log(payload, "payload inside patch ");
            let obj = props.item.checklist_item_result[props.item.checklist_item_result.length - 1];

            payload.Id = obj.Id
            console.log("latestAnsFromOutcomeApi", latestAnsFromOutcomeApi)
            payload.sireAnswer = JSON.parse(JSON.stringify(latestAnsFromOutcomeApi))
            const tempLatest = JSON.parse(JSON.stringify([...latestAnsFromOutcomeApi]));

            parentArr = [];
            for (let i = 0; i < tempLatest.length; i++) {
                childArr = [];
                if (tempLatest[i].child.length > 0) {
                    one(tempLatest[i].child)
                    parentArr.push(childArr)
                }

            }

            for (let i = 0; i < tempLatest.length; i++) {

                tempLatest[i].child = parentArr[i]
            }
            console.log("tempLatest", tempLatest);
            payload.reportAnswer = {};
            payload.reportAnswer.answer = tempLatest;


            //const itemStatus = item.MLA.every(item1 => payload.reportAnswer.answer.some(item2 => item1.Id === item2.Id && item2.child.length > 0));
            //  payload.reportAnswer.answerPass = itemStatus;
            if (payload.reportAnswer.answer[0].child.length > 0) {
                payload.reportAnswer.answerPass = true;
            }



            //  console.log("payload", payload)
            //  console.log("parentArr", parentArr)
            //  console.log("tempLatest", tempLatest)
            // console.log("payload.reportAnswer", payload.reportAnswer)
            updateChecklistItemAnswer(payload).then((res) => {
                if (res) {
                    toast.success("Answer submited successfully !", {
                        autoClose: 1000,
                        theme: "light",
                    })
                    //console.log("props.sectionDetails", props.sectionDetails);

                    if (val !== "delete" || "edit") {
                        props.fetchSurveySectionItems({
                            customer_id: localData.customer_id,
                            survey_id: id,
                            user: localData.user,
                            section_id: props.sectionDetails.id,
                        });
                        props.fetchSurveyStatus();
                    }
                }
            }).catch((er) => {
                return toast.error(`${er}`)
            })
        }
    }

    // Handling the photo delete 
    const handle_photo_delete = (obj, con_obj, arr) => {

        let a = arr.filter((item) => item.Id !== obj.Id);
        console.log(a, "remaining photo")
        SaveAnswer(a);
        const params = {
            Id: obj.Id,
        };
        delChecklistItemAttachment(params).then(
            (res) => {
                console.log("res", res);
                toast.success("Attachment Deleted Successfully...", {
                    autoClose: 3000,
                    theme: "light",

                });
                getLocalStoDataAndFire("delete")
            },
            (err) => {
                console.log("err", err);
            }
        );


    }

    // Handling the phoot edit 
    const handle_edit = () => {
        const reqPayload = {
            Id: filename.data.Id,
            name: filename.updated_name,
        };
        SaveAnswer(null, 'update', reqPayload)

        var formdata = new FormData();

        for (var key in reqPayload) {
            formdata.append(key, reqPayload[key]);
        }
        renameChecklistItemAttachment(formdata).then(
            (res) => {
                console.log("res", res);
                toast.success("Attachment renamed successfully", {
                    autoClose: 3000,
                    theme: "light",

                });
                getLocalStoDataAndFire("edit")
            },
            (err) => {
                console.log("err", err);
                toast.error("Failed to rename attachment", {
                    autoClose: 3000,
                    theme: "light",

                });
            }
        );

    }

    return (
        <React.Fragment>

            <Accordion className="fontFamily mb-2" activeKey={value} key={value}>
                <div className="default-according style-1" id="accordion">
                    <Card ref={props.myRef}>
                        <CardHeader className="p-0 bg-light">
                            <h5 className="mb-0">
                                <Button as={Card.Header}
                                    style={{
                                        position: "relative",
                                    }}
                                    className='btn btn-link text-white'
                                    color='light' onClick={() => toggle(value, item)}>
                                    {`${item.SrNo}) ${item.section}`}

                                    <span
                                        className="ms-5"
                                        style={{
                                            height: "15px",
                                            width: "15px",
                                            position: "absolute",
                                            right: "10px",
                                            top: "50%",
                                            transform:
                                                "translate(0, -50%)",
                                            borderRadius: "50%",
                                            background: props.item?.answer_pass == true ?
                                                "#51EE27" : "red",
                                        }}
                                    ></span>
                                </Button>
                            </h5>
                        </CardHeader >
                        <Collapse isOpen={isOpen === value}>

                            <CardBody>
                                <Nav className="nav-pills my-nav-pills" tabs>
                                    {item.MLA.map((mla, mlai) => (
                                        <NavItem key={mlai}>
                                            <NavLink id={`MLA${mla.Id}${0}`} className={BasicTab === parseInt(mlai + 1) ? 'active cursor_pointer' : 'cursor_pointer'}
                                                onClick={() => {
                                                    setBasicTab(parseInt(mlai + 1)); fetchLevOne(mla); setContent({});
                                                    // localStorage.setItem(`MLA`,JSON.stringify(mlai+1))
                                                    // localStorage.setItem('MLA_DATA',JSON.stringify(mla))
                                                }}>{mla.description}</NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                                {item.MLA.map((mla, mlai) => (
                                    <TabContent activeTab={BasicTab} key={mlai}>
                                        <TabPane id={`MLA${mla.Id}`} className="fade show" tabId={parseInt(mlai + 1)}>
                                            <div className="row mt-4">
                                                <div className={props.call ? "col-12" : "col-12 col-md-3"}>
                                                    {jsx.loading == true ?

                                                        <div className="loader-box justify-content-center">
                                                            <div className="loader">
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                                <div className="line bg-dark"></div>
                                                            </div>
                                                        </div>
                                                        : Object.keys(jsx.data).length > 0 ?
                                                            <div className="mla_list"
                                                                style={{
                                                                    overflowX: "scroll", width: '100%', maxHeight: '300px',
                                                                    height: 'auto', overflowY: 'auto'
                                                                }}>

                                                                <ul id="myUL" style={{ whiteSpace: "nowrap", }}>
                                                                    {jsx.data}
                                                                </ul>

                                                            </div>
                                                            : null}
                                                </div>

                                                <div className={props.call ? "col-12 mt-2" : "col-12 col-md-9"}>
                                                    {Object.keys(content).length > 0 ?
                                                        <div className="card shadow-sm" style={{ border: '1px solid #c6c6c6' }}>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <label>
                                                                            <span className="f-w-600">Description :</span>{content.srno} {content.description}
                                                                        </label>
                                                                    </div>



                                                                    {content.soc ? <div className="row">
                                                                        <div className="col-12 col-md-5 m-2">
                                                                            <button className="btn btn-primary-blue text-center" onClick={() => {
                                                                                console.log("clicked")
                                                                                toggle9();
                                                                            }}>View Answer {content.commentAns ? (`(${content.commentAns.length})`) : null}</button>
                                                                        </div>

                                                                    </div> :
                                                                        <div>
                                                                            {content.commentAns && content.commentAns !== undefined && <label className="f-w-600">Answered Comment: {content.commentAns[0]}</label>}
                                                                        </div>
                                                                    }


                                                                    {content.comments !== "NR" ?
                                                                        <div className={props.call ? "col-12" : "col-12 col-md-6"}>
                                                                            <label className="f-w-600">Comment
                                                                                {content.comments == "Mandatory" ?
                                                                                    <span className="text-danger">*</span> : null}
                                                                            </label>

                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                placeholder="Enter comments"
                                                                                onChange={(e) => {

                                                                                    setComment(e.target.value);

                                                                                    //  setContent({ ...content, commentAns: e.target.value });

                                                                                }}
                                                                            // value={comment!==""&&comment }



                                                                            />

                                                                        </div>
                                                                        : null}

                                                                    {content.photo !== "NR" ?
                                                                        <div className={props.call ? "col-12" : "col-12 col-md-6"}>

                                                                            <label className="f-w-600">Photo
                                                                                {content.photo == "Mandatory" ?
                                                                                    <span className="text-danger">*</span> : null}
                                                                            </label>
                                                                            <input
                                                                                className="form-control"
                                                                                type="file"
                                                                                accept="image/png, image/jpeg"
                                                                                key={photo_input}
                                                                                onChange={(e) => {
                                                                                    if (!e.target.files[0].type.startsWith('image/')) {
                                                                                        toast.error("Upload only image files !", {
                                                                                            autoClose: 1000,
                                                                                            theme: "light",

                                                                                        });
                                                                                        return
                                                                                    }


                                                                                    setPhotos(e.target.files[0])
                                                                                }}
                                                                            />


                                                                        </div>
                                                                        : null}

                                                                    {content.soc && <div className={props.call ? "col-12 my-2" : "col-12 col-md-6 my-2"}>
                                                                        <label className="f-w-600 f-14">{"Select SOC"}{content.soc ? <span className="text-danger">*</span> : null}</label>
                                                                        {content.soc && soclist.data.length > 0 ? <Tree treeData={soclist.data}
                                                                            handleselect={handleselect}
                                                                            soc_selected={soc_selected}
                                                                        /> : <p className="mb-0 text-danger">No SOC available</p>}
                                                                    </div>}


                                                                    {/* //&& content.socAns[0]?.SocMasterChild.length == 0  this && condition is present here*/}
                                                                    {content.soc && socans?.SocMasterChild == 0 && soclist.data.length > 0 && <div className={props.call ? "col-12 mb-2" : "col-12 col-md-6 mb-2"}>
                                                                        <label className="f-w-600 f-14">{"Select NOC"}{content.soc ? <span className="text-danger">*</span> : null}</label>

                                                                        <Typeahead
                                                                            id="basic-typeahead"
                                                                            labelKey="noc"
                                                                            multiple={false}
                                                                            options={noclist.data}
                                                                            loading={noclist.loading}
                                                                            // selected={content.nocAns && content.nocAns?.noc ?
                                                                            //     [{ noc: content.nocAns?.noc }] : []}
                                                                            // emptyLabel={
                                                                            //     [].length > 0
                                                                            //         ? "No matches found."
                                                                            //         : "No Parents to select"
                                                                            // }
                                                                            placeholder="Select NOC*"

                                                                            onChange={(val) => {
                                                                                setNocans(val[0]);
                                                                                // console.log("contentnocans",content.nocAns)
                                                                                //  console.log("val",val)
                                                                                // setContent({ ...content, nocAns:val[0] });
                                                                            }}
                                                                        />

                                                                    </div>}



                                                                    <div className="row">
                                                                        <div className={props.call ? "col-12 col-md-6 mt-3" : "col-12 col-md-3 mt-4"}>

                                                                            <button className="btn btn-primary-blue  text-center"
                                                                                onClick={() => SaveAnswer()}>Save</button>
                                                                        </div>

                                                                        {content.photo !== "NR" ?
                                                                            <div className={props.call ? "col-12 col-md-6 mt-3" : "col-12 col-md-3 mt-4"}>
                                                                                <button className="btn btn-primary-blue text-center"
                                                                                    onClick={() => {
                                                                                        toggle8();
                                                                                        handleAddingPhoto();
                                                                                    }}
                                                                                >Gallery</button>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}


                                                    {/* {Object.keys(content).length > 0 ?
                                                        <div className="d-flex justify-content-end">
                                                            <button
                                                                style={{ maxWidth: '160px' }}
                                                                className="btn btn-primary-blue text-center mt-3"
                                                                onClick={() => { getLocalStoDataAndFire() }}>Submit</button>
                                                        </div>
                                                        : null} */}
                                                </div>
                                            </div>



                                        </TabPane>
                                    </TabContent>
                                ))}

                            </CardBody>
                        </Collapse>
                    </Card>
                </div>
            </Accordion>





            <Modal isOpen={modal8} toggle={toggle8} size="lg">
                <ModalHeader toggle={toggle8} className="bg-primary-blue">{"Gallery"}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="row">
                            <div className={"col-12 col-md-6"}>
                                <label className="f-w-600">Edit file Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter file name"
                                    onChange={(e) => {
                                        setFilename({ ...filename, updated_name: e.target.value });
                                    }}
                                    value={filename.updated_name}
                                />
                            </div>
                            <div className="col-12 col-md-4 ">
                                <label className="invisible">Edit file Name</label>
                                <button className="btn btn-primary-blue text-center" onClick={handle_edit}>Save Changes</button>
                            </div>
                        </div>

                        <div className="row mt-3">
                            {content?.photoAns?.length > 0 ?
                                content?.photoAns?.map((val, ind, arr) => (

                                    <div className="col-12 col-md-3" key={Math.ceil(
                                        Math.random() *
                                        100000
                                    )}>
                                        {/* <img src={val?.checklist_file} className="img-fluid mb-3" alt="img" style={{ height: '160px', width: "160px" }} /> */}
                                        <div
                                            id="attachment_parent"

                                        >
                                            <div className="image_attachments m-2">
                                                <img

                                                    style={{
                                                        borderRadius:
                                                            "20px",
                                                    }}
                                                    className="cursor_pointer"
                                                    src={
                                                        val.checklist_file
                                                    }
                                                    alt=""
                                                    height={
                                                        "150"
                                                    }
                                                    width={
                                                        "100%"
                                                    }
                                                />
                                                <div
                                                    onClick={() => {
                                                        console.log("comment");
                                                        window
                                                            .open(
                                                                val.checklist_file,
                                                                "_blank"
                                                            )
                                                            .focus()
                                                    }}

                                                    className="overlay d-flex align-items-center justify-content-center"
                                                >
                                                    <Eye
                                                        style={{
                                                            color:
                                                                "white",
                                                        }}
                                                        height={
                                                            "20px"
                                                        }
                                                        width={
                                                            "20px"
                                                        }
                                                    />
                                                </div>

                                                <div className="date_overlay">
                                                    <p
                                                        className="mb-0"
                                                        style={{
                                                            lineHeight:
                                                                "1",
                                                        }}
                                                        onClick={() => {
                                                            //console.log("edit ",val.name)
                                                            setFilename({ data: val, updated_name: val.name });
                                                        }}
                                                    >

                                                        {val.name}<br />
                                                        {new Date(val.created).toLocaleString('en-GB', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: '2-digit',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            hour12: false
                                                        })}
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    background:
                                                        "white",
                                                    height:
                                                        "18px",
                                                    width:
                                                        "18px",
                                                    position:
                                                        "absolute",
                                                    borderRadius:
                                                        "50%",
                                                    top: "2px",
                                                    right:
                                                        "2px",
                                                }}
                                            >
                                                <XCircle

                                                    onClick={() => {
                                                        handle_photo_delete(val, content, arr);
                                                    }}
                                                    className="cursor_pointer"
                                                    style={{
                                                        color:
                                                            "red",
                                                    }}
                                                    height={
                                                        "18px"
                                                    }
                                                    width={
                                                        "18px"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : null}  </div>

                        <div className="row ">

                            <hr />

                            <div className="col">
                                <h5 className="mb-1 text-center">Site Coordinator Uploaded Photos</h5>
                                <div className="row my-1">
                                    <div className="col">
                                        {photosVideos.loading ? (
                                            <div className="loader-box justify-content-center">
                                                <div className="loader">
                                                    <div className="line bg-dark"></div>
                                                    <div className="line bg-dark"></div>
                                                    <div className="line bg-dark"></div>
                                                    <div className="line bg-dark"></div>
                                                </div>
                                            </div>
                                        ) : (
                                            <Slider
                                                {...basic_settings2}
                                                style={{
                                                    height: "160px",
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {photosVideos.data.filter((el) => {
                                                    return el.fileType == "Image";
                                                }).length == 0 ? (
                                                    <span className="text-danger text-center">
                                                        No photos available
                                                    </span>
                                                ) : (
                                                    photosVideos.data
                                                        .filter((el) => {
                                                            return (
                                                                el.fileType == "Image" && el.checkListItem == null
                                                            );
                                                        })
                                                        .map((el1) => {
                                                            return (
                                                                <div
                                                                    key={Math.ceil(Math.random() * 100000)}
                                                                    className="col-md-4 col-sm-6 position-relative px-2 mt-3"
                                                                // onMouseEnter={}
                                                                >
                                                                    <div className="position-relative">
                                                                        <img
                                                                            // onClick={() => {
                                                                            //   setPreview(el1);
                                                                            // }}
                                                                            onClick={() => previwAdd(el1)}
                                                                            src={el1.checklist_file}
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "25vh",
                                                                                border: preview?.includes(el1)
                                                                                    ? "2px solid #2246AB"
                                                                                    : "",
                                                                                // padding: "10px",
                                                                            }}
                                                                        />

                                                                        <p
                                                                            className="mb-0 text-muted text-center"
                                                                            id={"datePara"}
                                                                        >
                                                                            {new Date(el1.created).toLocaleString()}
                                                                            <br />
                                                                            {el1.name}
                                                                        </p>
                                                                        {/* <button
                                                                            // onClick={(event) => deletePhoto(el1, event)}
                                                                            type="button"
                                                                            className="btn position-absolute top-0 end-0 p-0  rounded-pill align-items-center d-flex justify-content-center"
                                                                            style={{
                                                                                width: "20px",
                                                                                height: "20px",
                                                                                background: "gray",
                                                                            }}
                                                                        >
                                                                            x
                                                                        </button> */}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                )}
                                            </Slider>
                                        )}
                                    </div>
                                </div>
                                {/* <h5 className="mb-1 text-center">Videos</h5>
                                <div className="row ">
                                    <div className="col">
                                        <Slider
                                            {...basic_settings2}
                                            style={{
                                                height: "160px",
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {photosVideos.data.filter((el) => {
                                                return el.fileType == "Video";
                                            }).length == 0 ? (
                                                <span className="text-danger text-center">
                                                    No videos Available
                                                </span>
                                            ) : (
                                                photosVideos.data
                                                    .filter((el) => {
                                                        return (
                                                            el.fileType == "Video" && el.checkListItem == null
                                                        );
                                                    })
                                                    .map((el1) => {
                                                        return (
                                                            <div
                                                                key={Math.ceil(Math.random() * 100000)}
                                                                className="col-md-4 col-sm-6 position-relative px-2 mt-3"
                                                                style={{ display: "flex", alignItems: "center" }}
                                                                onClick={() => previwAdd(el1)}
                                                            >
                                                                <div className="position-relative">
                                                                    <video
                                                                        src={el1.checklist_file}
                                                                        // style={{ height: "100px", width: "100px" }}
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "25vh",
                                                                            border: preview.includes(el1)
                                                                                ? "2px solid blue"
                                                                                : "",
                                                                            // padding: "10px",
                                                                        }}
                                                                        controls={true}
                                                                    />

                                                                    <p
                                                                        className="mb-0 text-muted text-center"
                                                                        id={"datePara"}
                                                                    >
                                                                        {new Date(el1.created).toLocaleString()}<br />
                                                                        {el1.name}
                                                                    </p>
                                                                    <button
                                                                        onClick={(event) => {
                                                                         //   deletePhoto(el1, event);
                                                                        }}
                                                                        type="button"
                                                                        className="btn position-absolute top-0 end-0 p-0  rounded-pill align-items-center d-flex justify-content-center"
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            background: "gray",
                                                                        }}
                                                                    >
                                                                        x
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                            )}
                                        </Slider>
                                    </div>
                                </div> */}

                            </div>

                            <div className="shadow border mb-2">
                                <div className="row ">
                                    <div
                                        className="col p-1"
                                        style={{ height: "270px", width: "250%" }}
                                    >
                                        {preview.length ? (
                                            preview[preview.length - 1]?.fileType == "Image" ? (
                                                <img
                                                    className="p-1 mb-4"
                                                    src={preview[preview.length - 1]?.checklist_file}
                                                    style={{
                                                        height: "-webkit-fill-available",
                                                        width: "-webkit-fill-available",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            ) : (
                                                !!preview && (
                                                    <video
                                                        src={
                                                            Object.keys(preview[preview.length - 1]).includes(
                                                                "url"
                                                            )
                                                                ? preview[preview.length - 1]?.url
                                                                : preview[preview.length - 1]?.checklist_file
                                                        }
                                                        height="100%"
                                                        width="100%"
                                                        controls
                                                    />
                                                )
                                            )
                                        ) : (
                                            <span
                                                className="text-center"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "red",
                                                    justifyContent: "center",
                                                    fontSize: "20px"
                                                }}
                                            >
                                                Click the image for preview
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="my-1 mx-2 ">

                                    {preview.length > 0 ? (
                                        <div className="d-flex justify-content-center">
                                            {/* <input
                                                        style={{ height: "fit-content" }}
                                                        type="text"
                                                        className={
                                                            filenameErr.length > 0
                                                                ? "form-control is-invalid"
                                                                : "form-control"
                                                        }
                                                        onChange={(e) => {
                                                            var temp = e.target.value;
                                                            if (temp.length === 0) {
                                                                setFilenameErr("Required !");
                                                            } else {
                                                                setFilenameErr("");
                                                            }
                                                            setFilename({...filename,updated_name:e.target.value});
                                                        }}
                                                        value={filename.updated_name}
                                                        placeholder="Enter filename"
                                                    />

                                                    <button
                                                        style={{ height: "fit-content" }}
                                                         onClick={handle_edit}
                                                        className="btn btn-primary-blue ms-0 ms-md-2"
                                                    >
                                                        Rename
                                                    </button> */}

                                            <button
                                                disabled={preview ? false : true}
                                                className="btn btn-primary-blue ms-0 ms-md-2"
                                                style={{ height: "fit-content" }}
                                                onClick={() => SaveAnswer()}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    ) : null}
                                    {filenameErr.length > 0 ? (
                                        <p className="text-danger mt-1 mb-0">{filenameErr}</p>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>

            <Modal isOpen={modal9} toggle={toggle9} size="xl">
                <ModalHeader toggle={toggle9} className="bg-primary-blue">{"View Answer"}</ModalHeader>
                <ModalBody>
                    <div className="row">

                        {console.log("content", content)}

                        {content.soc ? <table className="table table-striped table-bordered">
                            <thead className="thead-light">
                                <tr
                                    className="f-18"
                                    style={{
                                        position: "sticky",
                                        top: "0",
                                        background: "white",
                                    }}
                                >
                                    <th scope="col">{"Sr.No"}</th>
                                    <th scope="col">{"SOC"}</th>
                                    <th scope="col">{"NOC"}</th>
                                    <th scope="col">{"Comment"}</th>
                                    <th scope="col">{"Action"}</th>
                                </tr>
                            </thead>
                            <tbody>

                                {Object.keys(content).length > 0 && content.commentAns && content.commentAns.length > 0 ? content.commentAns.map((val, i) => (
                                    <tr key={i} className="f-16">
                                        <td>{i + 1}</td>
                                        <td>{content.socAns[i].soc}</td>
                                        <td>{content.nocAns[i].noc !== undefined && content.nocAns[i].noc}</td>
                                        <td>{val}</td>
                                        <td>


                                            <Trash2
                                                onClick={() => delete_soc_noc_commet(i)}
                                                id={"deleteToolTip" + i}
                                                className="cursor_pointer ms-1"
                                                height={"18px"}
                                                width={"18px"}
                                            />
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target={"deleteToolTip" + i}
                                            >
                                                {"Delete"}
                                            </UncontrolledTooltip>
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table> : null}




                    </div>

                </ModalBody>
            </Modal>
        </React.Fragment >)
}

export default memo(Sire_flow);





/// This is recursive funcion for creating tree stucture 
function Tree({ treeData, handleselect, soc_selected }) {
    if (treeData.length == 0) {
        return null
    }
    return (
        <ul>
            {treeData.map((node, ind) => (
                <TreeNode node={node} key={`${ind + node.soc}`} handleselect={handleselect} soc_selected={soc_selected} />
            ))}
        </ul>
    );
}
function TreeNode({ node, handleselect, soc_selected }) {
    const { SocMasterChild, id, soc, Id } = node;
    const [showChildren, setShowChildren] = useState(false);
    // console.log(node,"node");
    // console.log(soc_selected,"soc selected")
    const handleClick = () => {
        setShowChildren(!showChildren);
    };
    return (
        <>
            <div>
                <label className="f-14">
                    <span id="socItem" className={showChildren && SocMasterChild.length > 0 ? "caret2 caret2-down cursor_pointer" : "caret2 cursor_pointer"}
                        onClick={() => { handleselect(node); handleClick(); console.log("soc_selected", soc_selected) }}
                        style={{
                            color: (soc_selected.Id == Id && soc_selected.select) ? "#2264AB" : "black",
                            fontWeight: (soc_selected.Id == Id && soc_selected.select) ? "600" : "400"
                        }}>{soc}</span>
                    {/* <span className="caret" onClick={handleClick}> {SocMasterChild.length > 0 && "+"} </span> */}
                </label>
            </div>
            <ul style={{
                paddingLeft: "10px", borderLeft: "1px solid #242424",
            }}>
                {showChildren && <Tree treeData={SocMasterChild} handleselect={handleselect} soc_selected={soc_selected} />}
            </ul>
        </>
    );
}