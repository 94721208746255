import React, { useState } from "react";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal, } from "react-bootstrap";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { getVesselTypes, createVessel } from "../../../../api/index";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { convertDate } from "../../Common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    uniqueNumber: yup.string()
      .matches(/^[a-zA-Z0-9\s]*$/, "Only alphanumeric value are allowed !"),
    // .required("Vessel unique no is required !"),
    vesselIMONO: yup.string()
      .required("IMO No is required !")
      .test('isNumeric', 'Only Numeric value are allowed', value => /^[0-9]+$/.test(value))
      .test('notStartingWithZero', 'The number should not start with zero', value => !/^0/.test(value))
      ,
    vesselName: yup
      .string()
      .max(50, "50 characters allowed !")
      .required("Vessel name is required !"),
    vesselType: yup
      .array()
      .min(1, "Please select vessel type !")
      .nullable()
      .required("Please select vessel type !"),
    GT: yup
    .string()
    .test('match', 'Please enter valid GT', function (value) {
      return /^(?!0)\d*(\.\d+)?$/.test(value); 
    }),
    vesselInfo: yup.string().notRequired(),
    // status: yup
    //   .string()
    //   .nullable()
    //   .required("Status is required !")
    //   .matches(/^((?!Select).)*$/, "Please select valid option"),
  })
  .required();

var FormData = require("form-data");

function CreateVesselForm(props) {
  const [modal8, setModal8] = useState();
  const [visible, setVisible] = useState(false);
  const [vesselFiles, setVesselFiles] = useState([]);
  const [vesselType, setVesselType] = useState({ data: [], loading: false });

  const subscriptionPlan = JSON.parse(localStorage.getItem("subscription"));
  const customer_id = localStorage.getItem("customer_id");

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const toggle8 = () => {
   // setModal8(!modal8);
      setVisible(!visible);
    // resetting the form
    reset();

    if (visible === false || visible === undefined) {
      setVesselType({ ...vesselType, data: [], loading: true });
      getVesselTypes().then(
        (res) => {
          console.log("res", res);
          setVesselType({ ...vesselType, data: res.data, loading: false });
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };
  
  // const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
  // called every time a file's `status` changes

  const handleChangeStatus = ({ meta, file }, status) => {
    console.log("meta", meta);
    console.log("file", file);
    console.log("status", status);

    if (status === "done") {
      setVesselFiles([...vesselFiles, { id: meta.id, file: file }]);
    } else if (status === "removed") {
      console.log("vesselFiles", vesselFiles);

      setVesselFiles((curr) =>
        curr.filter((file) => {
          return file.id !== meta.id;
        })
      );
    }
  };

  const onSubmit = (data) => {
    if (data !== "") {
      // console.log("data", data);

      console.log("vesselFiles", vesselFiles);

      const reqPayload = {
        number: data.vesselIMONO,
        uniqueNumber: data.uniqueNumber,
        type_id: data.vesselType[0].Id,
        name: data.vesselName,
        GT: data.GT,
        status: true,
        customer_id: customer_id,
        fileCount: vesselFiles.length,
      };

      for (let i = 0; i < vesselFiles.length; i++) {
        reqPayload["file" + (parseInt(i) + 1)] = vesselFiles[i].file;
      }

      console.log("reqPayload", reqPayload);

      var formdata = new FormData();

      for (var key in reqPayload) {
        formdata.append(key, reqPayload[key]);
      }

      const currDate = new Date(new Date().setHours(0, 0, 0, 0));
      const planStartDate = new Date(
        new Date(new Date(subscriptionPlan.startDate)).setHours(0, 0, 0, 0)
      );
      const planEndDate = new Date(
        new Date(new Date(subscriptionPlan.endDate)).setHours(0, 0, 0, 0)
      );

      if (
        currDate >= planStartDate === true &&
        currDate <= planEndDate === true
      ) {
        console.log("valid");

        if (props.totalRecords < subscriptionPlan.plan.userCount) {
          console.log("valid");
          createVessel(formdata).then(
            (res) => {
              console.log("res", res);
              if (res.status === 200 || res.status === 201) {
                toast.success("Vessel created successfully !", {
                  autoClose: 3000,
                  theme: "light",
                });
               setVisible(false)

                props.fetchVessels("refresh");
              }
            },
            (err) => {
              console.log("err.response", err.response);
              if(err.response==undefined){
                toast.error("File size is too large !", {
                  autoClose: 3000,
                  theme: "light",
                });
              }
              else{
                if (err.response.status === 500) {
                  toast.error("Something went wrong, please try again !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                } else if (err.response.status === 403) {
                  toast.error("Invalid Subscription, contact admin !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                } else if (err.response.status === 400) {
                  let message=err.response.data.message;
                  if(err.response.data.message==="uniqueNumber unique required"){
                    message="This unique no. already present !"
                  }
                  toast.error(`${message}`, {
                    autoClose: 3000,
                    theme: "light",
                  });
                }else{

                  toast.error("Something went wrong, please try again !", {
                    autoClose: 3000,
                    theme: "light",
                  });
                }
              }
             
            }
          );
        } else {
          toast.error("Invalid Subscription, contact admin !", {
            autoClose: 3000,
            theme: "light",
          });
        }
      } else {
        console.log("invalid");
        toast.error("Invalid Subscription, contact admin !", {
          autoClose: 3000,
          theme: "light",
        });
      }
    } else {
      errors.showMessages();
    }
  };

  // const handleFilesSubmit = (files, allFiles) => {
  //     console.log(files.map(f => f.meta));
  //     // allFiles.forEach(f => f.remove())
  //   }

  return (
    <React.Fragment>
      <button
        onClick={()=>{setVisible(true);
          toggle8()
        }}
        style={{ fontSize: "16px " }}
        className="btn btn-primary-blue float-right"
      >
        Create New Vessel
      </button>

      <Modal show={visible} onHide={() => setVisible(false)} size="lg" backdrop="static">
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title > <p className="f-22 f-w-400 mb-0">{"Create New Vessel"}</p></Modal.Title>


        </Modal.Header>
        <Modal.Body>
          <form className="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"IMO No"}</label>
                  <input
                    className={
                      errors.vesselIMONO
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter IMO No*"
                    name="vesselIMONO"
                    {...register("vesselIMONO")}
                  />

                  <span className="text-danger">
                    {errors.vesselIMONO?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Unique No"}</label>
                  <input
                    className={
                      errors.uniqueNumber
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter Unique No*"
                    name="uniqueNumber"
                    {...register("uniqueNumber")}
                  />

                  <span className="text-danger">
                    {errors.uniqueNumber?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Vessel Name"}</label>
                  <input
                    className={
                      errors.vesselName
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter vessel name*"
                    name="vesselName"
                    {...register("vesselName")}
                  />

                  <span className="text-danger">
                    {errors.vesselName?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600">{"Vessel Type"}</label>

                  <Controller
                    name="vesselType"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={vesselType.data}
                        isLoading={vesselType.loading}
                        placeholder="Select vessel type"
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        isInvalid={errors.vesselType ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {errors.vesselType?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"GT"}</label>
                  <input
                    className={
                      errors.GT
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter GT*"
                    name="GT"
                    {...register("GT")}
                  />

                  <span className="text-danger">{errors.GT?.message}</span>
                </div>
              </div>

              {/* <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Status"}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue="Active"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue="Active"
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.status
                            ? "custom-select form-control is-invalid f-16"
                            : " f-16 custom-select form-control"
                        }
                      >
                        <option select="">{"Select*"}</option>
                        <option defaultValue="Active">{"Active"}</option>
                        <option defaultValue="Inactive">{"Inactive"}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div> */}

              <div className="col-12">
                <div className="form-group mb-2">
                  <label className="f-w-600 f-14">{"Vessel Info"}</label>

                  <Dropzone
                    // getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    multiple={true}
                    canCancel={false}
                    inputContent="Drop A File"
                  // onSubmit={handleFilesSubmit}
                  // styles={{
                  //     dropzone: { width: 400, height: 100 },
                  //     dropzoneActive: { borderColor: 'green' },
                  // }}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            style={{ fontSize: "16px " }}
            className="btn btn-primary-blue float-right"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default CreateVesselForm;
